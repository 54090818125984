export default {
  homePara:
    "Language localisation is the process of adapting a product's translation to a specific country or region. It is the second phase of a larger process of product translation and cultural adaptation to account for differences in distinct markets, a process known as internationalisation and localisation.",
  aboutPara:
    "This tutorial is targetted to make localization simple. Though there are a lot of tutorials available on the internet. But, this one will teach you the concept in a simple manner. You can directly use this code for your software. I have used 'Rreact' hooks which is software industry standard now. You can follow my blog to get more UI related articles which will make you a better 'UI' developer. {0}",
  changeLanguage: "Change your langauge",
  name: "Name",
  age: "Age",
  address: "Address",
  contactNo: "Contact No",
  save: "Save",
  formPara: "Please, enter your personal details here.",
  dynamicValue: "Dynamic Value- {randomNumber} {date}",

  //Header Text..
  navHow: "How it works",
  navTec: "Technology",
  navServ: "Services",
  navVisi: "Vision",
  navScr: "Screening",
  navVolu: "Volunteer",
  navDon: "Donate",
  navContact: "Contact us",
  navMedia: "Media",

  custTxt: "Your custom message text..",
  //Landing Page
  //Section 1.
  npo: "A Non-Profit initiative",
  npoCap:
    "Neurotechnology and artificial intelligence driven communication solutions",
  pscs: "Personalized Communication Solution",
  commPara: "Personalized communication solution",
  //Section 2. Card Header & Caption
  step1: "Consultation",
  step1Para: "Assessing the communication disablity.",
  step2: "Personalized Communication Strategy",
  step2Para:
    "Development of a personalized solution using signals from the brain and muscles.",
  step3: "Training and optimizing",
  step3Para:
    "Training and assessment of the communication performance. Adaptation of the hardware and the software with the changing state of the individual.",
  step4: "Continuous Support",
  step4Para:
    "An expert to facilitate communication according to the need of the individual and their family.",
  learnM: "Learn more",

  problem: "Losing the ability to communicate",
  problemStmt:
    'Many individuals lose the ability to speak due to amyotrophic lateral sclerosis (ALS), multiple sclerosis, stroke, traumatic brain injury, and other disorders.  When a patient can use their eye for communication with the rest of the body in a paralyzed state, they are said to be in a "locked-in state". In the case of ALS, most individuals’ disorder progresses until they lose control of their eye muscles, then they are said to be in a "completely locked-in state". These individuals are left without means of communication, thereby unable to express their needs, desires, and wishes.',
  solution: "We provide",
  solutionStmt:
    "Consultation, support, and enable communication using neurotechnology in such a critical state.",

  wePrd: "",
  how: "Our Motto",
  neroTech:
    "We at ALS Voice, based on our years of research and development, endeavor to provide a personalized means of communication services to individuals using cutting-edge neuro and biomedical technology.",

  // Section 3.
  //Header & captions
  oVal: "",
  commHr: "Communication for all",
  commDesc:
    "Being able to express oneself should be available to all regardless of financial accessibility",
  experHead: "Expanding Life Experiences",
  expPara:
    "We serve by providing cutting-edge artificial intelligence (AI)-powered by personalized biomedical and neurotechnology for communication",
  selfExpr: "Empowering self-expression",
  selfExprPara:
    "We empower individuals with communication deficits to express their voice",
  /** End of how it work page */

  //Footer Page
  ftFindUs: "FIND US ON:",
  ftNewsLttr: "SUBSCRIBE NEWSLETTER:",
  ftNeverShr: "We'll never share your email with anyone else.",
  ftPrivacy: "Privacy Policy",
  ftPolicy: "Imprint",
  ftSubcr: "You have subscribed successfully.",

  // Content for the footnote:
  ftNotes:
    "We are a non-profit and charitable organization: The Tübingen tax office has separately determined compliance with the statutory requirements under §§ 51, 59, 60, and 61 AO in a notice dated 04-Feb-2022 in accordance with § 60a AO.  In accordance with our Articles of Association, we promote aid for the disabled, welfare work, in particular, the purposes of officially recognized voluntary welfare associations (Section 23 of the Value Added Tax Implementation Ordinance), their sub-associations and their affiliated facilities and institutions, as well as charitable purposes, and are permitted to accept donations and issue donation receipts for these purposes.",
  /** End of Footer */

  //How it Work page
  //Header :
  meHeader: "Media coverage of communication possibility",
  hiwHeader: "Personalized Communication Solution",
  hiwDesc:
    'Many individuals lose the ability to speak due to amyotrophic lateral sclerosis (ALS), multiple sclerosis, stroke, traumatic brain injury, and other disorders.  When a patient can use their eye for communication with the rest of the body in a paralyzed state, they are said to be in a "locked-in state". In the case of ALS, most individuals’ disorder progresses until they lose control of their eye muscles, then they are said to be in a "completely locked-in state". These individuals are left without means of communication, thereby unable to express their needs, desires, and wishes.',
  hiwPntHdr:
    "We provide to these individuals personalized communication services where we offer:",
  //Points
  hiwPnt1:
    "Consultation and assessment to assess the problem and determine the appropriate communication approach and solution.",
  hiwPnt2:
    "Personalized Communication solution using neurotechnology in such a critical state.",
  hiwPnt3:
    "An expert to facilitate communication as per the need of the individual and their family and gradually one or more family members will be trained as well to enable communication without the help of a professional.",
  hiwPnt4:
    "Regular assessment of the communication performance, an adaption of the hardware and the software with the changing state of the individual, and continuous support.",
  hiwEnd:
    "Thus, ALS Voice provides end-to-end consultation, support, and assistance to enable communication using cutting-edge technology employing either the bio-signal from the different muscles of the body or brain signal from the top of the scalp and finally, the neural signal from the brain implant.",
  //Figure captions
  hiwFig1: "",
  hiwfig2: "",
  hiwfig3: "",
  hiwfig4: "",

  // Technology
  //Section 1.
  //Header
  tHeader:
    "A Brain-computer interface (BCI) enables an individual to control external electronic and mechanical devices using just the brain signal.",
  tH1: "Signals acquired using electrodes placed directly on the top of brain or inside the brain.",
  tH2: "Signals acquired using electrodes placed on the top of scalp outside the brain.",

  tsua: "SNA – Single neuron activity (SNA) is the signal acquired from a single neuron.",
  tmua: "MNA – Multi-neuron activity (MNA) is the signal acquired from multiple neurons.",
  tlfp: "LFP – Local field potential (LFP) reflects activity of several neurons in a given region of the brain.",
  tcog: "ECoG – Electrocorticography measures electrical activity from the outer surface of brain.",
  tnirs:
    "NIRS – Near-infrared spectroscopy (NIRS) measures the change in oxygen content of blood in a given region of the brain from the surface of the scalp.",
  tfmri:
    "fMRI – Functional magnetic resonance imaging (fMRI) measures brain activity by measuring blood oxygen level dependent change in the brain.",
  teeg: "EEG – Electroencephalography (EEG) measures electrical activity from the surface of the scalp.",
  teog: "EOG – Electrooculography (EOG) measures the electrical activity due to eye movement.",
  tibci:
    "Invasive BCI – An invasive BCI enables an individual to use the invasive brain signal to control external electronic and mechanical devices.",
  tnbci:
    "Non-invasive BCI – A non-invasive BCI enables an individual to use the non-invasive brain signal to control external electronic and mechanical devices.",
  tabb2:
    "The signal acquired is processed using state-of-art signal processing techniques and artificial intelligence algorithms to generate the control signal to external electronic and mechanical devices. This electronic and mechanical device can be the speller system that enables an individual with a communication deficit to communicate.",

  tlHeader: "Neurotechnology: The Next Frontier of Communication",
  tlReadSig: "Reading Signals of Body and Brain",
  tlReadSigDes:
    "Scientific developments and advancements enable us to measure and record different kinds of signals from our body. People have the ability to self-regulate some of these signals, namely electroencephalogram (EEG), electrooculogram (EOG), electromyogram (EMG), electrocorticogram (ECoG), and neural signals (NS), among a few others.",
  tlibci:
    "There is an emerging technology called a brain-computer interface (BCI)/ human-computer interface (HCI), where an individual can control external devices (computers, machines, objects) using the body signals mentioned above.",
  tlComm: "Communication using Signals from Body and Brain",
  tlCommDes1:
    "An individual can be trained to change these different body signals to produce signals. For external devices, this is called biofeedback and neurofeedback.",
  tlCommDes2:
    "The task-specific signal is processed by signal processing and artificial intelligence algorithms. The task-specific learning improves the communication technology.",
  tlCommDes3:
    "These algorithms map the recorded signals' task-specific patterns to generate commands to control an external device.",
  tlCommDes4:
    "Thereby, an individuals with communication deficits can be trained to use an external communication system even if completely paralyzed. Thus, an individual with a communication deficit can use the BCI/HCI technology to communicate their wishes and desires using only vision or hearing. ",

  /** End of technology page */

  // vision
  //Section 1.
  //Header
  vHeader: "Personalized Communication Solution",
  vPara1:
    "It is unimaginable to live without communication with the ever-shrinking and connected world. Although several systems are commercially available to serve as a communication aid for individuals with no means of communication, many cannot use these systems and are therefore left without any means of communication.",
  vPara2:
    "We are proposing a unique service to serve this unserved population. The organization aims to provide customized communication solutions to individuals who do not have any communication means. Our organization will give end-to-end consultation, support, and assistance to enable communication using either the physiological signal from the different muscles of the body or brain signal from the top of the scalp and finally, the neural signal from the brain implant. We are neither providing any medical treatment nor are we curing any disorder.",
  vPara3:
    "We will strive to support the desire of the individuals and their family members to communicate with each other. The motto is to enable communication. We will work with you as long as you desire and provide you with a means of communication employing cutting edge artificial intelligence-based biomedical and neurotechnology.",
  /** End of vision page */

  // Screening
  //Section 1.
  //Header
  sHeader: "Condition and communication means",
  sQ1: "1. Please select the condition",
  sQ1O1: "Amyotrophic lateral sclerosis (ALS)",
  sQ1O2: "Multiple sclerosis (MS)",
  sQ1O3: "Stroke",
  sQ1O4: "Traumatic brain injury (TBI)",
  sQ1O5: "Spinal cord injury",
  sQ1O6: "Others",

  sQ2: "2. Can s/he use eye for communication?",
  sQ2O1: "No",
  sQ2O2: "Yes (Please describe the level of efficiency)",

  sSituation1: "a). Please describe the living situation.",
  sSituation2: "b). Please describe the different visible muscular movements.",
  sNotes: "",
  sSubmit: "Submit",
  /** End of Screening page */

  // volunteer
  //Section 1.
  //Header
  vtHeader: "Volunteer with us",
  vtPara1:
    "ALS Voice is a non-profit organization which aims to provide personalized means of communication service to individuals with communication deficit. We need constant assistance of volunteers who can contribute to the cause. Without you we simply cannot do what we do.",
  vtPara2:
    "You are at the very heart of this great social cause. You can help us in different ways, such as raising awareness, helping with communication solutions, raising donations, and many more challenging avenues.",
  vtQuote:
    "The miracle is not that we do this work, but that we are happy to do it",
  vtQuoteN: "Mother Teresa",
  vtFaqH: "Volunteer FAQ’s",
  vtFaqP1:
    "Following are some questions and answers that may help you in making the decision of volunteering with us:",
  vtFaqQ1: "Do I get paid for my volunteering work?",
  vtFaqA1: "Not in monetary terms. It is an unpaid volunteering opportunity.",
  vtFaqQ2: "Are there fixed hours assigned for me to complete as a volunteer? ",
  vtFaqA2:
    "No. Depending upon the availability and spare time, one may volunteer accordingly.",
  vtFaqQ3: "What kind of work will the organization engage me in?",
  vtFaqA3:
    "From raising money to increasing awareness, inspiring people to join our register to supporting our remarkable donors, there’s a role to suit everyone. And in return, you’ll get to meet new people, gain new skills, boost your CV, and feel good knowing you’ve helped provide a voice to an individual with communication deficits.",
  vtFaqQ4: "What impact would I, as a volunteer, have on the organization? ",
  vtFaqA4:
    "The satisfaction derived from providing means of communication, and the contentment obtained from watching individuals with communication deficit communicate. By volunteering for us at ALS Voice, you are empowering self-expression.",
  vtFaqQ5: "What would be my incentive to become a part of your organization?",
  vtFaqA5:
    "We will engage you in a role that will suit you, based on the volunteer form you fill out. In return you get to hone your interpersonal, organizational and soft skills which will benefit your professional career as well as personal life. You will also receive a certificate of excellence for your volunteering effort.",
  /** End of volunteer page */

  // privacy policy
  //Section 1.
  //Header
  ppHeader: "Privacy Policy",
  ppIntro: "1. Introduction",
  ppIntroDes:
    "ALS Voice considers the privacy of its visitors to be extremely important. This privacy policy document describes in detail the types of personal information that is collected and recorded by www.alsvoice.org and how we use it. ALS Voice is committed to user privacy and ensures the security of personal data. Your data is conscientiously protected from loss, destruction, distortion/falsification, manipulation, unauthorized access, or unauthorized disclosure through appropriate technical and organizational measures. The following document discloses our privacy policy. The information included below is a detailed summary of what information is collected and where, through what partners, and how that information is being used.",
  ppTrafic: "2. Traffic Activity",
  ppTraficDes:
    "ALS Voice gGmbH maintains and manages the infrastructure that serves www.alsvoice.org. Currently, no website traffic activities are tracked by us.",
  ppCkes: "3. Cookies",
  ppCkeDes:
    "ALS Voice gGmbH does not use any cookies or tracking pixels for tracking user activity on its website www.alsvoice.org",
  ppDtCol: "4. Data Collection",
  ppDtColDes:
    "We will only collect personal identification information from you if you voluntarily submit such information to us while contacting us. The personal identification information includes name, email address, and phone number. You can always abstain from supplying personal identification information, understanding that it will prevent you from making contact with us.",
  ppDtRt: "5. Data Retention",
  ppDtRtDes:
    "We strive to keep our processing activities concerning your personal data as limited as possible. Upon using our services, personal data provided by you will be retained only for as long as we need it to fulfill the purpose for which we have collected it or as long as required by statutory retention requirements.",
  ppThrd: "6. Third Parties",
  ppThrdDes:
    "We do not sell, trade, or otherwise transfer to outside parties any information that we collect on www.alsvoice.org. We do not use any third-party vendors.",
  ppCnst: "7. Consent",
  ppCnstDes:
    "You hereby consent to our privacy policy and agree to its terms by using our application.",
  ppUsrRgt: "8. User Rights",
  ppUsrRgtDes:
    "Under the legislation applicable to you, you may be entitled to exercise some or all of the following rights:",
  ppUsr1:
    "Right of access (Art. 15 GDPR): You have the right to information regarding the data we process concerning you. Upon request, we will provide you with a copy of the data and additional information to the extent defined in Art. 15 GDPR.",
  ppUsr2:
    "Right to rectification (Art. 16 GDPR): You have the right to rectify your data wherever such data is incorrect or incomplete.",
  ppUsr3:
    "Right to erasure (Art. 17 GDPR): You have a right to erasure regarding data that is no longer required for the original purposes or that is processed unlawfully, as described in Art. 17 GDPR. Wherever certain data is subject to retention periods, we will restrict processing to the duration and intended purposes of such period instead of deleting the data.",
  ppUsr4:
    "Right to restriction of processing (Art. 18 GDPR): Upon your request, we will restrict processing of personal art according to Art. 18, wherever there are uncertainties regarding our right to process such data or while a decision regarding your objection to such processing is pending. In such cases, we will only retain data, restrict any processing to the minimum extent necessary and withdraw access to your data from our website management team.",
  ppUsr5:
    "Right to object to processing based on Art. 6 sec. 1 lit. f GDPR: Upon your objection we will cease any processing of your personal data based on Art. 6 lit. f. Wherever we have compelling legitimate grounds to process your data, we are allowed to process such data further, provided our interest in doing so prevails in a weighting against your interest against the processing activity. Therefore, to allow us to evaluate your request, please let us know the reason for your objection.",
  ppUsr6:
    "Right to withdraw consent: Where the processing is based on your consent, you have the right to withdraw your consent at any time by sending an email contact@alsvoice.org, without affecting the lawfulness of processing based on consent before its withdrawal.",
  ppUpdt: "9. Update",
  ppUpdtDes:
    "This Privacy Policy was last updated on: February 15,2022. Should we update, amend or make any changes to our privacy policy, those changes will be posted here.",
  ppInfo: "10. More Information",
  ppInfoDes:
    "If you require any more information or have any questions about our privacy policy or a request, please feel free to contact us by email at contact@alsvoice.org",
  ppAls: "11. ALS Voice Website Privacy Policy Only",
  ppAlsDes:
    "This privacy policy applies only to the www.alsvoice.org website and information shared and/or collected there. This policy does not apply to any information collected offline, or via channels other than www.alsvoice.org.",
  /** End of privacy policy page */

  // Legal page
  //Section 1.
  //Header
  leHeader: "Legal Terms",
  leImprt: "Imprint",
  leAd1: "ALS Voice gGmbH",
  leAd2: "Brunnenstrasse 23",
  leAd3: "72116, Moessingen, Germany",
  leMdHrd: "Managing director authorized to represent",
  leMdNme: "Name: Dr. Ujwal Chaudhary",
  leBdHdr: "",
  leBdN1: "",
  leBdN2: "",
  leBdN3: "",
  leCon: "Contact",
  lePhn: "",
  leEmail: "Email: contact@alsvoice.org",
  leRgtEnt: "Register entry",
  leRgPnt1: "Registration in the commercial register.",
  leRgPnt2: "Registered Court: The Court of Stuttgart",
  leRgPnt3: "Register number: HRB 782713",
  leRgPnt4: "Tax ID: 86168/05109",
  leRgPnt5: "VAT ID: DE349646979",
  leRgPnt6:
    "The European Commission provides a platform for online dispute resolution (OS): http://ec.europa.eu/consumers/odr",
  leRgPnt7: "You can find our e-mail address above in the Imprint.",
  leRgPnt8:
    "We are neither willing nor obliged to participate in dispute settlement proceedings before a consumer arbitration board.",
  leFtr: "Please direct your general queries to contact@alsvoice.org",
  /** End of Legal page page */

  /**Donation Page */
  dMkd: "Make a difference",
  dHlp: "Help us provide",
  dAlsD: "ALS Voice donation account",
  dComingS: "Online donation functionality coming soon",

  /**Contact Us */

  cuFname: "First Name",
  cuFnameP: "Please enter your firstname ",
  cuLname: "Last Name",
  cuLnameP: "Please enter your lastname ",
  cuEmail: "Email *",
  cuEmailP: "Please enter your email *",
  cuEmailE: "Valid email is required.",
  cuMsg: "Message *",
  cuMsgP: "Write your message here.",
  cuMsgE: "Please, leave us a message.",
  cuSend: "Send Message",

  cuSuccess:
    "Thank you for contacting our ALS Voice family! We will get back to you soon.",
  cuError: "Something went wrong ! Please reload the page and try again.",

  /**Donation */

  doTitle: "Your donation",
  doSubTitle: "Enter your desire amount:",
  doPlaceHolder: "Minimum amount 55",
  doYrDTitle: "Your data",
  doPersonalTle:
    "Please fill in your data. The fields signed with * are obligatory. We will never share your data with any other party.",
  doSalutation: "Salutation *",
  doSelect: "Please Select",
  doFname: "First Name *",
  doLname: "Last Name *",
  doEmail: "E-mail-Address *",
  doCertTitle:
    "For donation certificate, please provide the following information.",
  doStreet: "Street *",
  doNr: "Nr *",
  doZip: "Zip code *",
  doCity: "City *",
  doCountry: "Country *",
  doPayOption: "Your Payment Option",
  doServiceTxt: "Please choose",
  doRedirectS:
    "You will be automatic passed on to the Stripe Payment Gateway system",
  doDirectPay: "Direct Bank Payment to ALS Voice",
  doAlsAcc: "ALS Voice donation account",
  doCC: "Credit Card",
  doSubmit: "Submit",

  doValAmt: "Please enter Minimum amount of 55",
  doValSal: "Please select salutation ",
  doValName: "Please enter a value",
  doValEmail: "Email is invalid or missing",
  doValStreet: "Street is missing",
  doValNr: "Nr is invalid or missing",
  doValZip: "Zip code is invalid or missing",
  doValCity: "City is invalid or missing",
  doValCC: "Please choose at least 1 payment option service you want to pay",
  doVerTxt: "Please verify your details",
  doVerAmt: "Your selected donation amount:",
  doVerOpt: "Your selected payment method",
  doResTxt:
    "Responsible body: ALS Voice gGmbH. The processing is done for a proper donation, financial accounting, performing public interest tasks, and the legitimate interest of the ALS Voice gGmbH. You can find further information here.",
  doStsTxt: "Your Payment Status:",
  doTnx: "Thank you for your support!",
  doSuccessTxt: `Your donation helps us in providing a voice to people
  who have none. For Donation receipt, please email your
  donation details to contact@alsvoice.org. You will receive
  your donation certificate shortly.`,
  doWarmReg: "Warm regards and thanks from ALS Voice family",
  doErrTxt: "Something went wrong",
  doErrSbTxt: "Please try again. Your payment was not successful.",
  doErrCSTxt:
    "Your donation helps us in providing a voice to people who have none.",
  doDonation: "Donation",
  doBack: "Back",
  doHome: "Home",
  doSuccess: "Success",
  doTransparency: "Transparency",
  doTranTxt: `The ALS Voice raises money to provide a customized means of advanced
  neurotechnology-based communication solution to patients worldwide.`,
  doHlp: "Your donation helps!",
  doDonr: "ALS Voice donor service",
  doFrm: "Donation form",
  doOvr: "Data overview",
  doTransferred: "Donation transferred",

  doMis: "Miss",
  doMr: "Mr.",
  doFm: "Family",
  doMrMs: "Mr. & Mrs.",

  /**Speak Up */

  spChl: "What is #speakchallenge",
  spStoryElH: "Importance of Communication",
  spStoryEl:
    "Complete paralysis keeps individuals locked-in in their bodies without any possibility of expression. It pushes an individual to a state of exclusion. The #speakchallenge aims to raise awareness about the communication problem faced by completely paralyzed people, and ALS Voice organizes it.",
  spHowH: "How to participate in #speakchallenge?",
  spStoryVH: "Story of Valentina",
  spStoryV1:
    "My brother Fabio died 2 years ago. He had ALS. I'm working with engineers, scientists, and psychologists all around the world. We've found a non-profit organization and are promoting this #speakchallenge: “what would you say if you cannot say?”… my brother with eyes tracking told us: ",
  spStoryVQt: "“Life is a gift... enjoy it!”.",
  spStoryV2: "What will you say?. Follow and help us.",
  spChStpH: "The #SpeakChallenge is here!",
  spSt1H: "PARTICIPATE",
  spSt1Txt:
    "Record a small video or write “what you would say when you cannot say”.",
  spSt2H: "SHARE",
  spSt2Txt:
    "Share the recorded video or text with your friends, family, relatives, or anyone you know on different social media and use the Hashtag #alsvoice #speakchallenge.",
  spSt3H: "INVITE",
  spSt3Txt:
    "Invite your friends, relatives, and family members to participate in the #speakchallenge.",
  spSt4H: "DONATE",
  spSt4Txt:
    "ALS Voice is operated by a small group of volunteers. If you feel moved to support the project and would like to show your appreciation, you are warmly welcome to donate by clicking the button below.",
  spStoryElVideo: "koV9Io6DQW4",
};
