import React from "react";

import { CardHeader } from "../../components/Header/CardHeader";
import { Topbar } from "../../components/Navigations/Topbar";
import { Footer } from "../../components/Footer";
import { CarouselCard } from "../../components/Cards/CarouselCard";

import one from "../../assets/img/one.png";
import two from "../../assets/img/two.png";
import three from "../../assets/img/three.png";
import four from "../../assets/img/four.png";

import useTranslation from "../../components/customHooks/translations";
import { CarouselText } from "../../components/Cards/CarouselText";

const Landing = () => {
  const t = useTranslation();
  const cardList = [
    {
      title: t.step1.toUpperCase(),
      content: t.step1Para,
      bgurl: one,
      btnTxt: t.learnM.toUpperCase(),
      btnUrl: "/services",
    },
    {
      title: t.step2.toUpperCase(),
      content: t.step2Para,
      bgurl: two,
      btnTxt: t.learnM.toUpperCase(),
      btnUrl: "/services",
    },
    {
      title: t.step3.toUpperCase(),
      content: t.step3Para,
      bgurl: three,
      btnTxt: t.learnM.toUpperCase(),
      btnUrl: "/services",
    },
    {
      title: t.step4.toUpperCase(),
      content: t.step4Para,
      bgurl: four,
      btnTxt: t.learnM.toUpperCase(),
      btnUrl: "/services",
    },
  ];
  return (
    <div>
      <Topbar />
      <div className="container h-100" style={{ paddingTop: "6rem" }}>
        <CarouselText />
      </div>
      <div className="container-fluid">
        <div className="row">
          <div className="col">
            <div className="center ">
              <div className="card no-background">
                <div className="card-body">
                  <p className="card-text header">{t.npo}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container h-100">
        <div className="row h-100">
          <div className="col-12">
            <div className="card h-100 justify-content-center">
              <div className="card-body">
                <p
                  className="card-text-a font-weight-bold"
                  style={{ color: "#057bf6" }}
                >
                  {" "}
                  {t.problem}
                </p>
                <p className="card-text-a">{t.problemStmt}</p>
              </div>
            </div>
          </div>
          <div className="col-12">
            <div className="card h-100 justify-content-center">
              <div className="card-body">
                <p
                  className="card-text-a font-weight-bold"
                  style={{ color: "#057bf6" }}
                >
                  {" "}
                  {t.solution}
                </p>
                <p className="card-text-a">{t.solutionStmt}</p>
              </div>
            </div>
          </div>
          <div className="col-12">
            <div className="card h-100 justify-content-center">
              <div className="card-body">
                <p
                  className="card-text-a font-weight-bold"
                  style={{ color: "#057bf6" }}
                >
                  {" "}
                  {t.wePrd}
                </p>
                <p className="card-text-a"></p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className="testimonials text-center bg-light">
        <div
          id="carouselIndicators"
          className="carousel slide"
          data-ride="carousel"
        >
          <ol className="carousel-indicators">
            <li
              data-target="#carouselIndicators"
              data-slide-to="0"
              className="active"
            ></li>
            <li data-target="#carouselIndicators" data-slide-to="1"></li>
            <li data-target="#carouselIndicators" data-slide-to="2"></li>
            <li data-target="#carouselIndicators" data-slide-to="3"></li>
          </ol>
          <div className="carousel-inner">
            <div className="carousel-item active">
              <CarouselCard {...cardList[0]} />
            </div>
            {cardList.slice(1, cardList.length).map((obj, index) => (
              <div key={index} className="carousel-item ">
                <CarouselCard {...obj} />
              </div>
            ))}
          </div>
          <a
            className="carousel-control-prev"
            href="#carouselIndicators"
            role="button"
            data-slide="prev"
          >
            <span
              className="carousel-control-prev-icon"
              aria-hidden="true"
            ></span>
            <span className="sr-only">Previous</span>
          </a>
          <a
            className="carousel-control-next"
            href="#carouselIndicators"
            role="button"
            data-slide="next"
          >
            <span
              className="carousel-control-next-icon"
              aria-hidden="true"
            ></span>
            <span className="sr-only">Next</span>
          </a>
        </div>
      </section>
      <div className="container h-100">
        <div className="row h-100">
          <div className="col-12">
            <div className="card h-100 justify-content-center">
              <div className="card-body p-5">
                <p
                  className="card-text-a font-weight-bold"
                  style={{ color: "#057bf6" }}
                >
                  {" "}
                  {t.how}
                </p>
                <p className="card-text-a">{t.neroTech}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Landing;
