import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";

//Pages
import Landing from "./containers/landing";
import Works from "./containers/works";
import Donate from "./containers/donate";
import Condition from "./containers/condition";
import Services from "./containers/services";
import Vision from "./containers/vision";
import Volunteer from "./containers/volunteer";
import Policy from "./containers/policy";
import Legal from "./containers/legal";
import Contactus from "./containers/contactus";
import Technology from "./containers/technology";
import Media from "./containers/media";
import SpeakUp from "./containers/speakup";

const Routes = () => (
  <BrowserRouter>
    <Switch>
      <Route exact path="/" component={Landing} />
      <Route exact path="/services" component={Services} />
      <Route exact path="/donate" component={Donate} />
      <Route exact path="/how-it-work" component={Works} />
      <Route exact path="/condition-and-communication" component={Condition} />
      <Route exact path="/vision" component={Vision} />
      <Route exact path="/volunteer" component={Volunteer} />
      <Route exact path="/privacy-policy" component={Policy} />
      <Route exact path="/legal" component={Legal} />
      <Route exact path="/contact-us" component={Contactus} />
      <Route exact path="/technology" component={Technology} />
      <Route exact path="/media" component={Media} />
      <Route exact path="/speak-up" component={SpeakUp} />
      {/* <Route path="/donate-p" component={DonateP} /> */}
    </Switch>
  </BrowserRouter>
);

export default Routes;
