import React from "react";
import useTranslation from "../customHooks/translations";
import stripePaymentIcon from "../../assets/img/stripe-payments.png";
export const Declarations = () => {
  const t = useTranslation();
  return (
    <div className="col-lg-3 mx-auto">
      <div className="card justify-content-center">
        <div className="card-body">
          <p
            className="card-text-a font-weight-bold"
            style={{ color: "#057bf6" }}
          >
            {t.doTransparency}
          </p>
          <p
            className="card-text-a"
            style={{ color: "#554F4A", fontSize: "0.88rem" }}
          >
            {t.doTranTxt}
            <br />
            VAT ID: DE349646979
          </p>
        </div>
      </div>
      <span
        style={{
          color: "red",
          paddingLeft: "24px",
          fontSize: "1.3rem",
          lineHeight: "1.3",
          fontWeight: 100,
        }}
      >
        {t.doHlp}
      </span>
      <div className="card justify-content-center">
        <div className="card-body">
          <p
            className="card-text-a font-weight-bold"
            style={{ color: "#057bf6" }}
          >
            {t.doDonr}
          </p>
          <p
            className="card-text-a"
            style={{ color: "#554F4A", fontSize: "0.88rem" }}
          >
            {t.ftNotes}
          </p>
        </div>
      </div>
      <div className="card justify-content-center">
        <div className="card-body">
          <img
            src={stripePaymentIcon}
            alt="Stripe Payment Icon"
            style={{ width: "80%" }}
          />
        </div>
      </div>
    </div>
  );
};
