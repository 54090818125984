import React from 'react'
import { NavLink } from 'react-router-dom';

import { Topbar } from '../../components/Navigations/Topbar';
import { Footer } from '../../components/Footer';

import useTranslation from "../../components/customHooks/translations";

const Legal = ()=> {
    const t = useTranslation()
    return (
        <div>
            <Topbar/>
            <div className="container-fluid mt-5">
                <div className="row">
                    <div className="col">
                        <div className="center bottomMargin25 marginTop50 ">
                            <div className="card no-background">
                            <div className="card-body">
                                <p className="card-text header">{t.leHeader}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="container h-100">
            <div className="row h-100 py-2">
                <div className="col-12">
                    <div className="card h-100 justify-content-center" style={{border: 'none'}}>
                        <div className="card-body">
                        <h3 className="card-title">{t.leImprt}</h3>
                        <p className="card-text-a">
                                    <ul>
                                        <li>{t.leAd1}</li>  
                                        <li>{t.leAd2} </li>
                                        <li>{t.leAd3}</li>
                                    </ul>
                                </p>
                        </div>
                    </div>
                </div>
                <div className="col-12">
                    <div className="card justify-content-center" style={{border: 'none'}}>
                        <div className="card-body">
                        <h3 className="card-title">{t.leMdHrd}</h3>
                        <p className="card-text-a">{t.leMdNme} </p>
                        </div>
                    </div>
                </div>
                <div className="col-12">
                    <div className="card justify-content-center" style={{border: 'none'}}>
                        <div className="card-body">
                        <h3 className="card-title">{t.leCon}</h3>
                        <p className="card-text-a">{t.lePhn} </p>
                        <p className="card-text-a">{t.leEmail} </p>
                        </div>
                    </div>
                </div>
                <div className="col-12">
                    <div className="card h-100 justify-content-center" style={{border: 'none'}}>
                        <div className="card-body">
                        <h3 className="card-title">{t.leRgtEnt}</h3>
                        <p className="card-text-a">
                            <ul>
                                <li>{t.leRgPnt1}</li>  
                                <li>{t.leRgPnt2} </li>
                                <li>{t.leRgPnt3} </li>
                                <li>{t.leRgPnt4}</li>
                                <li>{t.leRgPnt5}</li>
                                <li>{t.leRgPnt6}</li>
                                <li>{t.leRgPnt7}</li>
                                <li>{t.leRgPnt8}</li>
                            </ul>
                        </p>
                        <p className="card-text-a">{t.leFtr} </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer/>
    </div>
        )
}

export default Legal;