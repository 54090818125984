import React from 'react'
import { NavLink } from 'react-router-dom';

import { Topbar } from '../../components/Navigations/Topbar';
import { Footer } from '../../components/Footer';

import main from '../../assets/img/main.svg'

import useTranslation from "../../components/customHooks/translations";

const Vision = ()=> {
    const t = useTranslation()
    return (
        <div>
            <Topbar/>
            <div className="container-fluid">
                    <div className="row">
                        <div className="col">
                            <div className="center marginTop50 ">
                                <div className="card no-background">
                                <div className="card-body">
                                    <p className="card-text header">{t.vHeader}</p>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                    </div>

                    <div className="container h-100">
            <div className="row h-100 py-2">
                <div className="col-12">
                    <div className="card h-100 justify-content-center">
                        <div className="card-body">
                        <h3 className="card-title">{t.navVisi}</h3>
                        <p className="card-text-a font-weight-bold" style={{color: '#057bf6'}}> {t.commHr}</p>
                        <p className="card-text-a">{t.commDesc}</p>
                        </div>
                    </div>
                </div>
                <div className="col-12">
                    <div className="card h-100 justify-content-center">
                        <div className="card-body">
                        <p className="card-text-a font-weight-bold" style={{color: '#057bf6'}}> {t.experHead}</p>
                        <p className="card-text-a">{t.expPara}</p>
                        </div>
                    </div>
                </div>
                <div className="col-12">
                    <div className="card h-100 justify-content-center">
                        <div className="card-body">
                        <p className="card-text-a font-weight-bold" style={{color: '#057bf6'}}> {t.selfExpr}</p>
                        <p className="card-text-a">{t.selfExprPara}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
            <div className="container h-100">
                <div className="row h-100">  
                    <div className="col-12">
                        <div className="card h-100 justify-content-center">
                            <div className="card-body">
                                
                                <p className="card-text-a">{t.vPara1}</p>
                                <p className="card-text-a">{t.vPara2} </p>
                                <section>
                                    <div className="container">
                                    <div className="row align-items-center">
                                    <div className="col-md-12">
                                        <div className="thumbnail">
                                            {/* <a href={main}> */}
                                                <div className="p-5">
                                                    <img src={main} alt="Lights" style={{width: '50%', float: 'left'}}/>
                                                    <div className="right-caption">
                                                    <p>{t.vPara3}</p>
                                                    </div>
                                                </div>
                                            {/* </a> */}
                                        </div>
                                    </div>                
                                    </div>
                                    </div>
                                </section>
                                {/* <p className="card-text-a">{t.vPara3}</p> */}
                            </div>
                        </div>
                    </div> 
                </div>
            </div>


            <Footer/>
        </div>
        )
}

export default Vision
