import React, { useState } from "react";
import axios from "axios";

import { Topbar } from "../../components/Navigations/Topbar";
import { Footer } from "../../components/Footer";

import useTranslation from "../../components/customHooks/translations";

const Condition = () => {
  const [success, setSuccess] = useState(false);
  const [message, setMessage] = useState("");
  const [error, setError] = useState(false);
  const [values, setValues] = useState({
    c1: "",
    c2: "",
    c3: "",
    c4: "",
    c5: "",
    c6: "",
    co1: "",
    co2: "",
    situation: "",
    musmov: "",
    email: "",
  });

  const onSubmitHandler = async (e) => {
    e.preventDefault();
    let currentCode = localStorage.getItem("code") || "gb";
    try {
      // console.log(values);
      if (values.situation && values.musmov) {
        // POST request using axios with async/await
        const result = await axios.post(
          `https://api-alsvoice.herokuapp.com/api/contact/screening/${currentCode}`,
          values
        );
        console.log(result);
        setSuccess(!result.data.isError);
        setMessage(result.data.message);
        reset();
        // console.log(message);
      }
    } catch (error) {
      setError(true);
    }
  };
  const onConditionChange = (event) => {
    event.persist();
    let isChecked = event.target.checked;
    if (isChecked) {
      setValues((values) => ({
        ...values,
        [event.target.name]: event.target.value,
      }));
    } else {
      setValues((values) => ({
        ...values,
        [event.target.name]: "",
      }));
    }
  };
  const onOtherConditionChange = (event) => {
    event.persist();
    setValues((values) => ({
      ...values,
      [event.target.name]: event.target.value,
    }));
  };
  const onTextareaChange = (event) => {
    event.persist();
    setValues((values) => ({
      ...values,
      [event.target.name]: event.target.value,
    }));
  };

  const reset = () => {
    document
      .querySelectorAll("input[type=checkbox]")
      .forEach((el) => (el.checked = false));
    setValues((values) => ({
      c1: "",
      c2: "",
      c3: "",
      c4: "",
      c5: "",
      c6: "",
      co1: "",
      co2: "",
      situation: "",
      musmov: "",
      email: "",
    }));
  };

  const t = useTranslation();
  return (
    <>
      <Topbar />
      <div className="container-fluid">
        <div className="row">
          <div className="col">
            <div className="center marginTop50 ">
              <div className="card no-background">
                <div className="card-body">
                  <p className="card-text header">{t.sHeader}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section>
        <div className="container align-items-center">
          <>
            <fieldset className="form-group my-5">
              <div className="row">
                <legend className="col-form-label col-sm-5 pt-0">
                  {t.sQ1}{" "}
                </legend>
                <div className="col-sm-3">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="c1"
                      id="gridCheckbox1"
                      value={t.sQ1O1}
                      onChange={onConditionChange}
                    />
                    <label className="form-check-label" htmlFor="gridCheckbox1">
                      {t.sQ1O1}
                    </label>
                  </div>
                </div>
                <div className="col-sm-3">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="c2"
                      id="gridCheckbox2"
                      value={t.sQ1O2}
                      onChange={onConditionChange}
                    />
                    <label className="form-check-label" htmlFor="gridCheckbox2">
                      {t.sQ1O2}
                    </label>
                  </div>
                </div>
                <div className="col-sm-1">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="c3"
                      id="gridCheckbox3"
                      value={t.sQ1O3}
                      onChange={onConditionChange}
                    />
                    <label className="form-check-label" htmlFor="gridCheckbox3">
                      {t.sQ1O3}{" "}
                    </label>
                  </div>
                </div>
              </div>
              <br />
              <div className="row">
                <legend className="col-form-label col-sm-5 pt-0"></legend>
                <div className="col-sm-3">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="c4"
                      id="gridCheckbox4"
                      value={t.sQ1O4}
                      onChange={onConditionChange}
                    />
                    <label className="form-check-label" htmlFor="gridCheckbox4">
                      {t.sQ1O4}
                    </label>
                  </div>
                </div>
                <div className="col-sm-3">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="c5"
                      id="gridCheckbox5"
                      value={t.sQ1O5}
                      onChange={onConditionChange}
                    />
                    <label className="form-check-label" htmlFor="gridCheckbox5">
                      {t.sQ1O5}
                    </label>
                  </div>
                </div>
              </div>
              <br />
              <div className="row">
                <legend className="col-form-label col-sm-5 pt-0"></legend>
                <div className="col-sm-1">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="gridCheckbox"
                      id="gridCheckbox6"
                      onChange={(e) => {
                        var el = document.getElementById("other-div");
                        el.style.display =
                          el.style.display !== "none" ? "none" : "";
                      }}
                    />
                    <label className="form-check-label" htmlFor="gridCheckbox5">
                      {t.sQ1O6}
                    </label>
                  </div>
                </div>
                <div
                  className="col-sm-6"
                  id="other-div"
                  style={{ display: "none" }}
                >
                  <div className="form-check">
                    <textarea
                      className="form-control"
                      onChange={onOtherConditionChange}
                      value={values.c6}
                      id="others-textarea"
                      rows="3"
                      name="c6"
                      placeholder={t.custTxt}
                    ></textarea>
                  </div>
                </div>
              </div>
            </fieldset>
            <fieldset className="form-group my-5">
              <div className="row">
                <legend className="col-form-label col-sm-5 pt-0">
                  {t.sQ2}
                </legend>
                <div className="col-sm-1">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="ventilated"
                      id="ventilated2"
                      onChange={(e) => {
                        var el = document.getElementById("efficiency-div");
                        el.style.display = "none";
                        setValues((values) => ({
                          ...values,
                          co1: "NO",
                          co2: "",
                        }));
                      }}
                    />
                    <label className="form-check-label" htmlFor="ventilated2">
                      {t.sQ2O1}
                    </label>
                  </div>
                </div>
              </div>
              <div className="row">
                <legend className="col-form-label col-sm-5 pt-0"></legend>
                <div className="col-sm-3">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="ventilated"
                      id="ventilated1"
                      onChange={(e) => {
                        var el = document.getElementById("efficiency-div");
                        el.style.display =
                          el.style.display !== "none" ? "none" : "";
                        setValues((values) => ({
                          ...values,
                          co1: "",
                          co2: "YES",
                        }));
                      }}
                    />
                    <label className="form-check-label" htmlFor="ventilated1">
                      {t.sQ2O2}
                    </label>
                  </div>
                </div>
                <div
                  className="col-sm-4"
                  id="efficiency-div"
                  style={{ display: "none" }}
                >
                  <div className="form-check">
                    <textarea
                      className="form-control"
                      onChange={onTextareaChange}
                      value={values.co2}
                      id="efficiency-textarea"
                      rows="3"
                      name="co2"
                      placeholder={t.custTxt}
                    ></textarea>
                  </div>
                </div>
              </div>
            </fieldset>
            <fieldset className="form-group my-5">
              <div className="form-check">
                <div className="form-group row my-5">
                  <label htmlFor="message" className="col-sm-6 col-form-label">
                    {t.sSituation1}
                  </label>
                  <div className="col-sm-6">
                    <textarea
                      className="form-control"
                      id="living-situation"
                      rows="3"
                      name="situation"
                      placeholder={t.custTxt}
                      onChange={onTextareaChange}
                      value={values.situation}
                    ></textarea>
                  </div>
                </div>
                <div className="form-group row my-5">
                  <label htmlFor="message" className="col-sm-6 col-form-label">
                    {t.sSituation2}
                  </label>
                  <div className="col-sm-6">
                    <textarea
                      className="form-control"
                      onChange={onTextareaChange}
                      value={values.musmov}
                      id=" muscular-movements"
                      rows="3"
                      name="musmov"
                      placeholder={t.custTxt}
                    ></textarea>
                  </div>
                </div>

                <div className="form-group row my-5">
                  <label htmlFor="message" className="col-sm-6 col-form-label">
                    Email
                  </label>
                  <div className="col-sm-6">
                    <input
                      type="text"
                      value={values.email}
                      name="email"
                      onChange={onTextareaChange}
                    />
                  </div>
                </div>
              </div>
              <small className="form-text text-muted">{t.sNotes}</small>
              <div className="col-sm-10">
                <button
                  type="submit"
                  onClick={onSubmitHandler}
                  className="btn btn-outline-orange"
                >
                  {t.sSubmit.toUpperCase()}
                </button>
              </div>
            </fieldset>
            <div className="form-group row my-5">
              <label
                htmlFor="message"
                className="col-sm-6 col-form-label"
              ></label>
              <div
                className="col-sm-6"
                style={{ color: "green", fontSize: "30px" }}
              >
                {message}
              </div>
            </div>
          </>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Condition;
