import React, { useState, useEffect } from "react";
import { useLanguageContext } from "./contexts/LanguageContext";

import Dropdown from 'react-bootstrap/Dropdown';
import { Form } from 'react-bootstrap';
import FlagIcon from './FlagIcon';


const LanguageHandler =()=> {
  const { language, changeLanguage } = useLanguageContext();

  const [countries] = useState([
    { code: 'gb', title: 'En'},
    { code: 'de', title: 'De'},
    { code: 'it', title: 'It'}
  ]);
  const [toggleContents, setToggleContents] = useState(<React.Fragment><FlagIcon code="gb"/> English</React.Fragment>);
  const [selectedCountry, setSelectedCountry] = useState();

  const [code, setCode] = useState("gb");

  useEffect(() => {
      let currentCode = localStorage.getItem("code") || 'gb';
      let currentTitle = localStorage.getItem("title") || 'En';
      changeLanguage(currentCode);
      setSelectedCountry(currentCode);
      setToggleContents(<React.Fragment><FlagIcon code={currentCode}/> {currentTitle}</React.Fragment>);
  },[code]);
  
  return (
    <React.Fragment>
    <Form style={{marginRight: '10px'}}>
        <Dropdown className="language"
          onSelect={eventKey => {
            const { code, title } = countries.find(({ code }) => eventKey === code);
            changeLanguage(code);
            setSelectedCountry(eventKey);
            setToggleContents(<React.Fragment><FlagIcon code={code}/> {title}</React.Fragment>);
            setCode(code);
            window.localStorage.setItem('code', code);
            window.localStorage.setItem('title', title);
          }}
        >
          <Dropdown.Toggle variant="" id="dropdown-flags" className="text-left">
            {toggleContents}
          </Dropdown.Toggle>

          <Dropdown.Menu>
            {countries.map(({ code, title }) => (
              <Dropdown.Item key={code} eventKey={code}><FlagIcon code={code}/> {title}</Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </Form>
    </React.Fragment>
  );
}

export default LanguageHandler;