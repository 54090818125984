import React, { useState } from "react";
import logo from "../../assets/img/ALS_logo.svg";
import useTranslation from "../../components/customHooks/translations";
import axios from "axios";
export const Footer = () => {
  const t = useTranslation();
  const [email, setEmail] = useState("");
  const [responseMsg, setResponseMsg] = useState("");
  const [success, setSuccess] = useState(false);
  const onSubmitHandler = async (e) => {
    e.preventDefault();
    let currentCode = localStorage.getItem("code") || "gb";
    try {
      if (email) {
        // POST request using axios with async/await
        const { isError, message } = await axios.post(
          `https://api-alsvoice.herokuapp.com/api/contact/subscribe/${currentCode}`,
          { email }
        );
        setResponseMsg(message);
        setSuccess(!isError);
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <React.Fragment>
      <footer className="footer">
        <div className="container">
          <div className="row">
            <div className="col-lg-2">
              <img
                src={logo}
                alt="logo"
                style={{ height: "50px", marginTop: "-10px" }}
              />
            </div>
            <div className="col-lg-3 p-3">
              <span>{t.ftFindUs.toUpperCase()}</span>
              <a
                className="btn-social mx-1"
                href="https://www.linkedin.com/company/als-voice-ggmbh/"
                target="_blank"
              >
                <i className="fab fa-linkedin"></i>
              </a>
              <a
                className="btn-social mx-1"
                href="https://www.youtube.com/channel/UCvvgq8-TsoLkSZKfQXEocdg"
                target="_blank"
              >
                <i className="fab fa-youtube"></i>
              </a>
              <a
                className="btn-social mx-1"
                href="https://www.instagram.com/alsvoice_/"
                target="_blank"
              >
                <i className="fab fa-instagram"></i>
              </a>
              <a
                className="btn-social mx-1"
                href="https://twitter.com/als_voice"
                target="_blank"
              >
                <i className="fab fa-twitter"></i>
              </a>
              <a
                className="btn-social mx-1"
                href="https://t.me/alsvoice"
                target="_blank"
              >
                <i className="fab fa-telegram"></i>
              </a>
            </div>
            {/* <!-- Footer Location--> */}
            <div className="col-lg-5">
              {!success && (
                <div className="input-group mb-3 sub-container">
                  <p className="sub-text-p">{t.ftNewsLttr}</p>
                  <input
                    type="text"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="form-control "
                    placeholder="Email"
                  />
                  <div className="input-group-append">
                    <button
                      className="btn btn-success"
                      type="submit"
                      onClick={onSubmitHandler}
                    >
                      Go
                    </button>
                  </div>
                </div>
              )}
              {success && <div>{t.ftSubcr}</div>}
            </div>
            {/* <!-- Footer About Text--> */}
            <div className="col-lg-1">
              <a href="donate" className="btn btn-blue">
                {t.navDon.toUpperCase()}
              </a>
            </div>
            <div className="col-lg-1">
              <a className="nav-link" href="vision">
                {t.navVisi.toUpperCase()}
              </a>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 ">
              <small id="emailHelp" className="form-text text-muted">
                {t.ftNotes}
              </small>
            </div>
          </div>
        </div>
      </footer>
      {/* <hr className="divider" /> */}
      {/* <!-- Copyright Section--> */}
      <section className="copyright py-4 text-center text-white">
        <div className="container">
          <small>
            <a href="privacy-policy" style={{ color: "white" }}>
              {t.ftPrivacy}
            </a>{" "}
            |{" "}
            <a href="legal" style={{ color: "white" }}>
              {t.ftPolicy}
            </a>
            <br />
            Copyright © ALS Voice 2022
          </small>
        </div>
      </section>
      {/* <!-- Scroll to Top Button (Only visible on small and extra-small screen sizes)--> */}
      <div className="scroll-to-top d-lg-none position-fixed">
        <a
          className="js-scroll-trigger d-block text-center text-white rounded"
          href="#page-top"
        >
          <i className="fa fa-chevron-up"></i>
        </a>
      </div>
    </React.Fragment>
  );
};
