import React from "react";

const cardList = [
  {
    title: "Brain Implant Allows Fully Paralyzed Patient to Communicate",
    author: "-	New York Times",
  },
  {
    title:
      "‘Emotional moment’: locked-in patient communicates with family via implant",
    author: "-	The Guardian",
  },
  {
    title: "A locked-in man has communicated in sentences by thought alone",
    author: "-	MIT Technology Review",
  },
  {
    title: "Brain implant allows paralyzed man to communicate, says study",
    author: "-	CBC Radio",
  },
  {
    title: "Computer communication for motor neuron disease patients",
    author: "-	Cosmos Magazine",
  },
  {
    title: "Brain implant allows totally paralyzed patient to communicate",
    author: "-	California 18",
  },
  {
    title:
      "How Brain Implant Led Fully Paralyzed Patient To Communicate in ‘Game Changing’ Experiment",
    author: "-	The paradise",
  },
];
export const CarouselText = () => {
  return (
    <div id="carouselMediaTxt" className="carousel slide" data-ride="carousel">
      <ol className="carousel-indicators">
        {cardList.map((obj, index) => (
          <li
            key={index}
            data-target="#carouselMediaTxt"
            data-slide-to={index}
            className={index === 0 ? "active" : ""}
          ></li>
        ))}
      </ol>
      <div className="carousel-inner">
        {cardList.slice(1, cardList.length).map((obj, index) => (
          <div
            key={index}
            className={index === 0 ? "carousel-item active" : "carousel-item"}
          >
            <p className="carousel-txt">{obj.title}</p>
            <p className="carousel-src">{obj.author}</p>
          </div>
        ))}
      </div>
      <a
        className="carousel-control-prev"
        href="#carouselMediaTxt"
        role="button"
        data-slide="prev"
      >
        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
        <span className="sr-only">Previous</span>
      </a>
      <a
        className="carousel-control-next"
        href="#carouselMediaTxt"
        role="button"
        data-slide="next"
      >
        <span className="carousel-control-next-icon" aria-hidden="true"></span>
        <span className="sr-only">Next</span>
      </a>
    </div>
  );
};
