import React from "react";
import useTranslation from "../../customHooks/translations";

export const Steps = ({ stepCount }) => {
  const t = useTranslation();
  return (
    <div className="container-fluid mt-5">
      <div className="row">
        <div className="col-lg-8" style={{ marginLeft: "auto" }}>
          <div className="steps">
            <span className={`step1 ${stepCount > 0 ? "active" : ""}`}>
              {t.doFrm}
            </span>
            <span className={`step2 ${stepCount > 1 ? "active" : ""}`}>
              {t.doOvr}
            </span>
            <span className={`step3 ${stepCount > 2 ? "active" : ""}`}>
              {t.doTransferred}
            </span>
            <div className="clearfix"></div>
          </div>
        </div>
      </div>
    </div>
  );
};
