export default {
  homePara:
    "Language localisation is the process of adapting a product's translation to a specific country or region. It is the second phase of a larger process of product translation and cultural adaptation to account for differences in distinct markets, a process known as internationalisation and localisation.",
  aboutPara:
    "This tutorial is targetted to make localization simple. Though there are a lot of tutorials available on the internet. But, this one will teach you the concept in a simple manner. You can directly use this code for your software. I have used 'Rreact' hooks which is software industry standard now. You can follow my blog to get more UI related articles which will make you a better 'UI' developer. {0}",
  changeLanguage: "Change your langauge",
  name: "Name",
  age: "Age",
  address: "Address",
  contactNo: "Contact No",
  save: "Save",
  formPara: "Please, enter your personal details here.",
  dynamicValue: "Dynamic Value- {randomNumber} {date}",

  //Header Text..
  navHow: "Wie es funktioniert",
  navTec: "Technologie",
  navServ: "Dienstleistungen",
  navVisi: "Visionen",
  navScr: "Screening",
  navVolu: "Ehrenamt",
  navDon: "Spenden",
  navContact: "Kontakt",
  navMedia: "Media",
  custTxt: "Ihr individueller Nachrichtentext..",

  //Landing Page
  //Section 1.
  npo: "Eine gemeinnützige Initiative",
  npoCap:
    "Neurotechnologie und auf künstlicher Intelligenz basierende Kommunikationslösungen",
  pscs: "Personalisierte Kommunikationslösung",
  commPara: "Personalisierte Kommunikationslösung",
  //Section 2. Card Header & Caption
  step1: "Beratung",
  step1Para: "Bewertung der Kommunikationsstörung.",
  step2: "Personalisierte Kommunikationsstrategie",
  step2Para:
    "Entwicklung einer personalisierten Lösung, die die Signale des Gehirns und der Muskeln nutzt.",
  step3: "Schulung und Optimierung",
  step3Para:
    "Training und Bewertung der Kommunikationsleistung. Anpassung der Hardware und der Software an den sich ändernden Zustand des Individuums.",
  step4: "Kontinuierliche Unterstützung",
  step4Para:
    "Ein Experte, der die Kommunikation je nach den Bedürfnissen des Einzelnen und seiner Familie erleichtert.",
  learnM: "Erfahren Sie mehr",

  problem: "Verlust der Fähigkeit zu kommunizieren",
  problemStmt:
    'Viele Menschen verlieren aufgrund von amyotropher Lateralsklerose (ALS), Multipler Sklerose, Schlaganfall, traumatischen Hirnverletzungen und anderen Erkrankungen die Fähigkeit zu sprechen.  Wenn ein Patient seine Augen zur Kommunikation nutzen kann, während sein restlicher Körper in einem gelähmten Zustand ist, spricht man von einem "Locked-in-Zustand". Bei ALS schreitet die Erkrankung bei den meisten Betroffenen so weit fort, dass sie die Kontrolle über ihre Augenmuskeln verlieren, dann spricht man von einem "Complete Locked-in-Zustand". Diese Menschen haben keine Kommunikationsmittel mehr und können daher ihre Bedürfnisse, Wünsche und Sehnsüchte nicht mehr äußern.',
  solution: "Wir bieten",
  solutionStmt:
    "Beratung, Unterstützung und Ermöglichung der Kommunikation mit Hilfe der Neurotechnologie in einem solchen kritischen Zustand.",

  wePrd: "",
  how: "Unser Motto",
  neroTech:
    "Wir von ALS Voice bemühen uns auf der Grundlage unserer jahrelangen Forschungs- und Entwicklungsarbeit darum, Menschen mit Hilfe modernster neuro- und biomedizinischer Technologie ein personalisiertes Kommunikationsmittel zur Verfügung zu stellen.",
  // Section 3.
  //Header & captions
  oVal: "",
  commHr: "Kommunikation für alle",
  commDesc:
    "Sich ausdrücken zu können, sollte allen unabhängig von der finanziellen Zugänglichkeit zur Verfügung stehen",
  experHead: "Erweiterung der Lebenserfahrungen",
  expPara:
    "Wir bieten hochmoderne künstliche Intelligenz (KI), die durch personalisierte biomedizinische und neurotechnische Technologien für die Kommunikation unterstützt wird",
  selfExpr: "Stärkung der Selbstdarstellung",
  selfExprPara:
    "Wir befähigen Menschen mit Kommunikationsdefiziten, ihre Stimme auszudrücken",
  /** End of how it work page */

  //Footer Page
  ftFindUs: "FINDEN SIE UNS AUF:",
  ftNewsLttr: "NEWSLETTER ABONNIEREN:",
  ftNeverShr: "Wir werden Ihre E-Mail-Adresse niemals an Dritte weitergeben.",
  ftPrivacy: "Datenschutz",
  ftPolicy: "Impressum",
  ftSubcr: "Sie haben sich erfolgreich angemeldet.",
  ftNotes:
    "Wir sind gemeinnützig und mildtätig: Das Finanzamt Tübingen hat mit Bescheid vom 04-Feb-2022 nach § 60a AO die Einhaltung der satzungsmäßigen Voraussetzungen nach den §§ 51, 59, 60 und 61 AO gesondert festgestellt. Wir fördern nach unserer Satzung die Hilfe für Behinderte, das Wohlfahrtswesen, insbesondere die Zwecke der amtlich anerkannten Verbände der freien Wohlfahrtspflege (§ 23 der Umsatzsteuer-Durchführungsverordnung), ihrer Unterverbände und ihrer angeschlossenen Einrichtungen und Anstalten sowie mildtätige Zwecke und dürfen dafür Spenden entgegennehmen und Zuwendungsbestätigungen ausstellen.",
  /** End of Footer */

  //How it Work page
  //Header :
  hiwHeader: "Personalisierte Kommunikationslösung",
  hiwDesc:
    'Viele Menschen verlieren aufgrund von amyotropher Lateralsklerose (ALS), Multipler Sklerose, Schlaganfall, traumatischen Hirnverletzungen und anderen Erkrankungen die Fähigkeit zu sprechen.  Wenn ein Patient seine Augen zur Kommunikation nutzen kann, während sein restlicher Körper in einem gelähmten Zustand ist, spricht man von einem "Locked-in-Zustand". Bei ALS schreitet die Erkrankung bei den meisten Betroffenen so weit fort, dass sie die Kontrolle über ihre Augenmuskeln verlieren, dann spricht man von einem "Complete Locked-in-Zustand". Diese Menschen haben keine Kommunikationsmittel mehr und können daher ihre Bedürfnisse, Wünsche und Sehnsüchte nicht mehr äußern.',
  meHeader: "Medienberichterstattung über die Möglichkeit der Kommunikation",
  hiwPntHdr:
    "Wir stellen diesen Personen personalisierte Kommunikationsdienste zur Verfügung, die wir anbieten:",
  //Points
  hiwPnt1:
    "Beratung und Bewertung zur Beurteilung des Problems und zur Festlegung des geeigneten Kommunikationsansatzes und der Lösung.",
  hiwPnt2:
    "Personalisierte Kommunikationslösung mit Hilfe der Neurotechnologie in einem solch kritischen Zustand.",
  hiwPnt3:
    "Ein Experte, der die Kommunikation entsprechend den Bedürfnissen der Person und ihrer Familie erleichtert, und nach und nach werden auch ein oder mehrere Familienmitglieder geschult, um die Kommunikation ohne die Hilfe einer Fachkraft zu ermöglichen.",
  hiwPnt4:
    "Regelmäßige Bewertung der Kommunikationsleistung, Anpassung der Hard- und Software an den sich ändernden Zustand des Einzelnen und kontinuierlicher Support.",
  hiwEnd:
    "So bietet ALS Voice eine umfassende Beratung, Unterstützung und Hilfe an, um die Kommunikation mit Hilfe modernster Technologie zu ermöglichen, die entweder die Biosignale der verschiedenen Muskeln des Körpers oder die Gehirnsignale der Kopfhaut und schließlich die neuronalen Signale des Gehirnimplantats nutzt.",
  //Figure captions
  hiwFig1: "",
  hiwfig2: "",
  hiwfig3: "",
  hiwfig4: "",

  // Technology
  //Section 1.
  //Header
  tHeader:
    "Eine Gehirn-Computer-Schnittstelle (BCI) ermöglicht es einer Person, externe elektronische und mechanische Geräte allein durch das Gehirnsignal zu steuern.",
  tH1: "Signale, die mit Hilfe von Elektroden erfasst werden, die direkt auf der Oberfläche des Gehirns oder im Gehirn angebracht werden.",
  tH2: "Die Signale werden mit Hilfe von Elektroden erfasst, die auf der Kopfhaut außerhalb des Gehirns angebracht werden.",

  tsua: "SNA – Die Aktivität eines einzelnen Neurons (SNA) ist das von einem einzelnen Neuron erfasste Signal.",
  tmua: "MNA – Multi-Neuronen-Aktivität (MNA) ist das von mehreren Neuronen erfasste Signal.",
  tlfp: "LFP – Das lokale Feldpotential (LFP) spiegelt die Aktivität mehrerer Neuronen in einer bestimmten Hirnregion wider.",
  tcog: "ECoG – Die Elektrokortikografie misst die elektrische Aktivität an der Außenfläche des Gehirns.",
  tnirs:
    "NIRS – Die Nahinfrarotspektroskopie (NIRS) misst die Veränderung des Sauerstoffgehalts des Blutes in einer bestimmten Hirnregion von der Oberfläche der Kopfhaut aus.",
  tfmri:
    "fMRI – Die funktionelle Magnetresonanztomographie (fMRI) misst die Gehirnaktivität, indem sie die vom Blutsauerstoffgehalt abhängigen Veränderungen im Gehirn misst.",
  teeg: "EEG – Die Elektroenzephalografie (EEG) misst die elektrische Aktivität an der Oberfläche der Kopfhaut.",
  teog: "EOG – Die Elektrookulographie (EOG) misst die elektrische Aktivität, die durch Augenbewegungen verursacht wird.",
  tibci:
    "Invasives BCI - Ein invasives BCI ermöglicht es einer Person, das invasive Gehirnsignal zur Steuerung externer elektronischer und mechanischer Geräte zu nutzen.",
  tabb2:
    "Das erfasste Signal wird mit modernsten Signalverarbeitungstechniken und Algorithmen der künstlichen Intelligenz verarbeitet, um das Steuersignal für externe elektronische und mechanische Geräte zu erzeugen. Dieses elektronische und mechanische Gerät kann das Buchstabiersystem sein, das es einer Person mit einem Kommunikationsdefizit ermöglicht, zu kommunizieren.",

  tlHeader: "Neurotechnologie: Die nächste Grenze der Kommunikation",
  tlReadSig: "Signale von Körper und Gehirn lesen",
  tlReadSigDes:
    "Wissenschaftliche Entwicklungen und Fortschritte ermöglichen es uns, verschiedene Arten von Signalen aus unserem Körper zu messen und aufzuzeichnen. Der Mensch hat die Fähigkeit, einige dieser Signale selbst zu regulieren, nämlich das Elektroenzephalogramm (EEG), das Elektrookulogramm (EOG), das Elektromyogramm (EMG), das Elektrokortikogramm (EKoG) und die neuronalen Signale (NS), um nur einige zu nennen.",
  tlibci:
    "Es gibt eine aufkommende Technologie, die als Gehirn-Computer-Schnittstelle (BCI)/Human-Computer-Schnittstelle (HCI) bezeichnet wird und bei der eine Person externe Geräte (Computer, Maschinen, Gegenstände) mit Hilfe der oben genannten Körpersignale steuern kann.",
  tlComm: "Kommunikation mit Signalen von Körper und Gehirn",
  tlCommDes1:
    "Eine Person kann trainiert werden, diese verschiedenen Körpersignale zu verändern, um Signale zu erzeugen. Bei externen Geräten wird dies als Biofeedback und Neurofeedback bezeichnet.",
  tlCommDes2:
    "Das aufgabenspezifische Signal wird durch Algorithmen der Signalverarbeitung und der künstlichen Intelligenz verarbeitet. Das aufgabenspezifische Lernen verbessert die Kommunikationstechnologie.",
  tlCommDes3:
    "Diese Algorithmen bilden die aufgabenspezifischen Muster der aufgezeichneten Signale ab, um Befehle zur Steuerung eines externen Geräts zu erzeugen.",
  tlCommDes4:
    "Dadurch können Personen mit Kommunikationsdefiziten trainiert werden, ein externes Kommunikationssystem zu benutzen, selbst wenn sie vollständig gelähmt sind. So kann eine Person mit einem Kommunikationsdefizit die BCI/HCI-Technologie nutzen, um ihre Wünsche und Sehnsüchte nur über das Sehen oder Hören zu kommunizieren. ",

  /** End of technology page */

  // vision
  //Section 1.
  //Header
  vHeader: "Personalisierte Kommunikationslösung",
  vPara1:
    "Ein Leben ohne Kommunikation ist in der immer kleiner werdenden und vernetzten Welt unvorstellbar. Obwohl mehrere Systeme im Handel erhältlich sind, die als Kommunikationshilfe für Menschen ohne Kommunikationsmittel dienen, können viele diese Systeme nicht nutzen und sind daher ohne jegliche Kommunikationsmittel.",
  vPara2:
    "Wir bieten einen einzigartigen Dienst für diese unversorgte Bevölkerung an. Ziel der Organisation ist es, maßgeschneiderte Kommunikationslösungen für Menschen anzubieten, die über keinerlei Kommunikationsmittel verfügen. Unsere Organisation bietet umfassende Beratung, Unterstützung und Hilfe an, um die Kommunikation zu ermöglichen, indem sie entweder das physiologische Signal der verschiedenen Muskeln des Körpers oder das Gehirnsignal von der Kopfhaut und schließlich das neurale Signal des Gehirnimplantats nutzt. Wir bieten weder eine medizinische Behandlung an, noch heilen wir irgendeine Störung.",
  vPara3:
    "Wir werden uns bemühen, den Wunsch der Betroffenen und ihrer Familienangehörigen zu unterstützen, miteinander zu kommunizieren. Das Motto lautet, Kommunikation zu ermöglichen. Wir werden so lange mit Ihnen arbeiten, wie Sie es wünschen, und Ihnen ein Kommunikationsmittel zur Verfügung stellen, das auf künstlicher Intelligenz basierende Biomedizin- und Neurotechnologie einsetzt.",
  /** End of vision page */

  // Screening
  //Section 1.
  //Header
  sHeader: "Zustand und Kommunikationsmittel",
  sQ1: "1. Bitte wählen Sie der Zustand",
  sQ1O1: "Amyotrophe Lateralsklerose (ALS)",
  sQ1O2: "Multiple Sklerose (MS)",
  sQ1O3: "Schlaganfall",
  sQ1O4: "Traumatische Hirnverletzung (TBI)",
  sQ1O5: "Rückenmarksverletzung",
  sQ1O6: "Sonstiges",

  sQ2: "2. Kann er/sie die Augen zur Kommunikation nutzen?",
  sQ2O1: "Nein",
  sQ2O2: "Ja (Bitte beschreiben Sie den Grad der Effizienz)",

  sSituation1: "a). Beschreiben Sie die Wohnsituation.",
  sSituation2:
    "b). Beschreiben Sie die verschiedenen sichtbaren Muskelbewegungen.",
  sNotes: "",
  sSubmit: "Einreichen",
  /** End of Screening page */

  // volunteer
  //Section 1.
  //Header
  vtHeader: "Freiwillig mitarbeiten",
  vtPara1:
    "ALS Voice ist eine gemeinnützige Organisation, die sich zum Ziel gesetzt hat, Menschen mit Kommunikationsdefiziten personalisierte Kommunikationsdienste zur Verfügung zu stellen. Wir sind ständig auf die Unterstützung von Freiwilligen angewiesen, die einen Beitrag zu dieser Sache leisten können. Ohne Sie können wir nicht tun, was wir tun.",
  vtPara2:
    "Sie sind das Herzstück dieser großen sozialen Sache. Sie können uns auf verschiedene Weise helfen, z. B. bei der Sensibilisierung, bei Kommunikationslösungen, beim Sammeln von Spenden und bei vielen anderen Herausforderungen.",
  vtQuote:
    "Das Wunder ist nicht, dass wir diese Arbeit tun, sondern dass wir sie gerne tun.",
  vtQuoteN: "Mother Teresa",
  vtFaqH: "Freiwillige FAQ's",
  vtFaqP1:
    "Nachfolgend finden Sie einige Fragen und Antworten, die Ihnen bei der Entscheidung für einen Freiwilligendienst bei uns helfen könnten:",
  vtFaqQ1: "Werde ich für meine Freiwilligentätigkeit bezahlt?",
  vtFaqA1:
    "Nicht in finanzieller Hinsicht. Es handelt sich um eine unbezahlte Freiwilligenarbeit.",
  vtFaqQ2:
    "Gibt es festgelegte Stunden, die ich als Freiwilliger absolvieren muss?",
  vtFaqA2:
    "Nein. Je nach Verfügbarkeit und freier Zeit kann man sich entsprechend engagieren.",
  vtFaqQ3: "Mit welcher Art von Arbeit wird mich die Organisation betrauen?",
  vtFaqA3:
    "Ob es darum geht, Geld zu sammeln, das Bewusstsein zu schärfen, Menschen zu inspirieren, sich in unser Register einzutragen oder unsere bemerkenswerten Spender zu unterstützen - es gibt für jeden eine passende Aufgabe. Und im Gegenzug lernen Sie neue Leute kennen, erwerben neue Fähigkeiten, verbessern Ihren Lebenslauf und haben das gute Gefühl, dass Sie dazu beigetragen haben, Menschen mit Kommunikationsdefiziten eine Stimme zu geben.",
  vtFaqQ4:
    "Welchen Einfluss würde ich als Freiwilliger auf die Organisation haben?:",
  vtFaqA4:
    "Die Befriedigung, die sich aus der Bereitstellung von Kommunikationsmitteln ergibt, und die Zufriedenheit, die sich einstellt, wenn man sieht, wie Menschen mit Kommunikationsdefiziten kommunizieren. Indem Sie bei ALS Voice ehrenamtlich mitarbeiten, fördern Sie die Selbstdarstellung.",
  vtFaqQ5: "Was wäre der Anreiz für mich, in Ihrer Organisation mitzuarbeiten?",
  vtFaqA5:
    "Auf der Grundlage des von Ihnen ausgefüllten Freiwilligenformulars werden wir Sie in einer Rolle einsetzen, die zu Ihnen passt. Im Gegenzug können Sie Ihre zwischenmenschlichen, organisatorischen und sozialen Fähigkeiten verbessern, was sowohl für Ihre berufliche Laufbahn als auch für Ihr Privatleben von Vorteil ist. Außerdem erhalten Sie ein Zertifikat für Ihr ehrenamtliches Engagement.",
  /** End of volunteer page */

  // privacy policy
  //Section 1.
  //Header
  ppHeader: "Datenschutzbestimmungen",
  ppIntro: "1. Einleitung",
  ppIntroDes:
    "ALS Voice misst dem Schutz der Privatsphäre seiner Besucher große Bedeutung bei. DieseDatenschutzerklärung beschreibt detailliert die Arten von persönlichen Daten, die von www.alsvoice.org gesammelt und aufgezeichnet werden, und wie wir sie verwenden. ALS Voice verpflichtet sich, die Privatsphäre der Nutzer zu schützen und die Sicherheit der persönlichen Daten zu gewährleisten. Ihre Daten werden gewissenhaft vor Verlust, Zerstörung, Verfälschung, Manipulation und unberechtigtem Zugriff oder unberechtigter Offenlegung durch geeignete technische und organisatorische Maßnahmen geschützt. Das folgende Dokument legt unsere Datenschutzpolitik offen. Die nachstehenden Informationen geben einen detaillierten Überblick darüber, welche Informationen wo und durch welche Partner gesammelt werden und wie diese Informationen verwendet werden.",
  ppTrafic: "2. Verkehr Aktivität",
  ppTraficDes:
    "Die ALS Voice gGmbH unterhält und verwaltet die Infrastruktur, die www.alsvoice.org zur Verfügung stellt. Derzeit werden von uns keine Aktivitäten auf der Website verfolgt.",
  ppCkes: "3. Cookies",
  ppCkeDes:
    "Die ALS Voice gGmbH verwendet keine Cookies oder Zählpixel zur Verfolgung der Nutzeraktivitäten auf ihrer Website www.alsvoice.org.",
  ppDtCol: "4. Datenerhebung",
  ppDtColDes:
    "Wir erheben nur dann personenbezogene Daten von Ihnen, wenn Sie uns diese im Rahmen der Vereinbarung eines Demotermins freiwillig zur Verfügung stellen. Zu den personenbezogenen Daten gehören Name, E-Mail-Adresse, Telefonnummer, Firmenname und Berufsbezeichnung. Sie können jederzeit auf die Angabe Ihrer persönlichen Daten verzichten, wenn Sie wissen, dass Sie dann keinen Kontakt mit uns aufnehmen können.",
  ppDtRt: "5. Datenaufbewahrung",
  ppDtRtDes:
    "Wir bemühen uns, die Verarbeitung Ihrer personenbezogenen Daten so gering wie möglich zu halten. Personenbezogene Daten, die Sie uns bei der Nutzung unserer Dienste zur Verfügung stellen, werden nur so lange aufbewahrt, wie wir sie zur Erfüllung des Zwecks, für den wir sie erhoben haben, benötigen oder wie es die gesetzlichen Aufbewahrungspflichten vorsehen.",
  ppThrd: "6. Dritte Parteien",
  ppThrdDes:
    "Wir verkaufen, handeln oder übertragen keine Informationen, die wir auf www.alsvoice.org sammeln, an Dritte. Wir arbeiten nicht mit Drittanbietern zusammen.",
  ppCnst: "7. Zustimmung",
  ppCnstDes:
    "Durch die Nutzung unserer Anwendung erklären Sie sich mit unserer Datenschutzrichtlinie einverstanden und stimmen deren Bedingungen zu.",
  ppUsrRgt: "8. Benutzerrechte",
  ppUsrRgtDes:
    "Nach dem für Sie geltenden Recht können Sie einige oder alle der folgenden Rechte ausüben:",
  ppUsr1:
    "Recht auf Auskunft (Art. 15 DSGVO): Sie haben das Recht auf Auskunft über die Daten, die wir über Sie verarbeiten. Auf Anfrage werden wir Ihnen eine Kopie der Daten zusammen mit zusätzlichen Informationen in dem in Artikel 15 DSGVO festgelegten Umfang zur Verfügung stellen.",
  ppUsr2:
    "Recht auf Berichtigung (Art. 16 DSGVO): Sie haben das Recht, Ihre Daten zu korrigieren, wenn diese nicht korrekt oder unvollständig sind.",
  ppUsr3:
    "Recht auf Löschung (Art. 17 GDPR): Sie haben ein Recht auf Löschung von Daten, die für die ursprünglichen Zwecke nicht mehr erforderlich sind oder die unrechtmäßig verarbeitet werden, wie in Art. 17 GDPR. Soweit bestimmte Daten Aufbewahrungsfristen unterliegen, werden wir die Verarbeitung auf die Dauer und die Zwecke dieser Fristen beschränken, anstatt die Daten zu löschen.",
  ppUsr4:
    "Recht auf Einschränkung der Verarbeitung (Art. 18 GDPR): Auf Ihr Verlangen schränken wir die Verarbeitung personenbezogener Daten gemäß Art. 18 einschränken, wenn Unsicherheiten hinsichtlich unseres Rechts zur Verarbeitung solcher Daten bestehen oder eine Entscheidung über Ihren Widerspruch gegen eine solche Verarbeitung anhängig ist. In solchen Fällen werden wir die Daten nur aufbewahren, die Verarbeitung auf das notwendige Mindestmaß beschränken und unserem Website-Management-Team den Zugang zu Ihren Daten entziehen.",
  ppUsr5:
    "Recht auf Widerspruch gegen die Verarbeitung auf der Grundlage von Art. 6 sec. 1 lit. f GDPR: Auf Ihren Widerspruch hin werden wir die Verarbeitung Ihrer personenbezogenen Daten auf der Grundlage von Art. 6 lit. f. Sofern wir zwingende schutzwürdige Gründe für die Verarbeitung Ihrer Daten haben, dürfen wir diese Daten weiterverarbeiten, sofern unser Interesse daran in einer Abwägung mit Ihrem Interesse an der Verarbeitung überwiegt. Damit wir Ihr Anliegen prüfen können, bitten wir Sie daher, uns den Grund Ihres Widerspruchs mitzuteilen.",
  ppUsr6:
    "Recht auf Widerruf der Einwilligung: Beruht eine Verarbeitung auf Ihrer Einwilligung, so haben Sie das Recht, Ihre Einwilligung jederzeit durch eine E-Mail an contact@alsvoice.org zu widerrufen, ohne dass die Rechtmäßigkeit der aufgrund der Einwilligung bis zum Widerruf erfolgten Verarbeitung berührt wird.",
  ppUpdt: "9. Aktualisierung",
  ppUpdtDes:
    "Diese Datenschutzrichtlinie wurde zuletzt aktualisiert am: 15. Februar 2021. Sollten wir unsere Datenschutzrichtlinie aktualisieren, ergänzen oder ändern, werden diese Änderungen hier veröffentlicht.",
  ppInfo: "10. Weitere Informationen",
  ppInfoDes:
    "Wenn Sie weitere Informationen benötigen oder Fragen zu unseren Datenschutzrichtlinien oder eine Anfrage haben, können Sie uns gerne per E-Mail unter contact@alsvoice.org  kontaktieren.",
  ppAls: "11. Datenschutzrichtlinie für die ALS Voice Website",
  ppAlsDes:
    "Diese Datenschutzrichtliniegilt nur für die Website www.alsvoice.org und die dort geteilten und/oder gesammelten Informationen. Diese Richtlinie gilt nicht für Informationen, die offline, oder über andere Kanäle als www.alsvoice.org gesammelt werden.",
  /** End of privacy policy page */

  // Legal page
  //Section 1.
  //Header
  leHeader: "Rechtliche Bedingungen",
  leImprt: "Impressum",
  leAd1: "ALS Voice gGmbH",
  leAd2: "Brunnenstraße 23",
  leAd3: "72116, Mössingen, Deutschland",
  leMdHrd: "Vertretungsberechtigter Geschäftsführer:",
  leMdNme: "Name: Dr. Ujwal Chaudhary",
  leBdHdr: "",
  leBdN1: "",
  leBdN2: "",
  leBdN3: "",
  leCon: "Kontakt",
  lePhn: "",
  leEmail: "Email: contact@alsvoice.org",
  leRgtEnt: "Registereintrag",
  leRgPnt1: "Eintragung im Handelsregister",
  leRgPnt2: "Registergericht: Amtsgericht Stuttgart",
  leRgPnt3: "Registernummer: HRB 782713",
  leRgPnt4: "Steuer-Identifikationsnummer: 86168/05109",
  leRgPnt5: "UMSATZSTEUER-ID: DE349646979",
  // "",
  leRgPnt6:
    "Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit: http://ec.europa.eu/consumers/odr",
  leRgPnt7: "Unsere E-Mail-Adresse finden Sie oben im Impressum.",
  leRgPnt8:
    "Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.",
  leFtr: "Bitte senden Sie Ihre allgemeinen Fragen an contact@alsvoice.org",
  /** End of Legal page page */

  /**Donation Page */
  dMkd: "Einen Unterschied machen",
  dHlp: "Helfen Sie uns",
  dAlsD: "ALS Voice Spendekonto",
  dComingS: "Online-Spendenfunktion demnächst verfügbar",

  /**Contact Us */

  cuFname: "Vorname",
  cuFnameP: "Bitte geben Sie Ihren Vornamen ein ",
  cuLname: "Nachname",
  cuLnameP: "Bitte geben Sie Ihren Nachnamen ein ",
  cuEmail: "E-Mail *",
  cuEmailP: "Bitte geben Sie Ihre E-Mail ein *",
  cuEmailE: "Gültige E-Mail ist erforderlich.",
  cuMsg: "Nachricht *",
  cuMsgP: "Schreiben Sie Ihre Nachricht hier.",
  cuMsgE: "Bitte hinterlassen Sie uns eine Nachricht.",
  cuSend: "Nachricht senden",

  cuSuccess:
    "Vielen Dank, dass Sie unsere ALS Voice-Familie kontaktiert haben. Wir werden uns bald wieder bei Ihnen melden.",
  cuError:
    "Da ist etwas schief gelaufen! Bitte laden Sie die Seite neu und versuchen Sie es erneut.",

  /**Donation */
  doTitle: "Ihre Spende",
  doSubTitle: "Geben Sie Ihren Wunschbetrag ein:",
  doPlaceHolder: "Mindestbetrag 55",
  doYrDTitle: "Ihre Daten",
  doPersonalTle:
    "Bitte geben Sie Ihre Daten ein. Die mit * gekennzeichneten Felder sind obligatorisch. Wir werden Ihre Daten niemals an Dritte weitergeben.",
  doSalutation: "Anrede *",
  doSelect: "Bitte auswählen",
  doFname: "Vorname *",
  doLname: "Nachname *",
  doEmail: "E-Mail Adresse *",
  doCertTitle:
    "Für eine Spendenbescheinigung geben Sie bitte die folgenden Informationen an.",
  doStreet: "Straße *",
  doNr: "Nr *",
  doZip: "Postleitzahl *",
  doCity: "Stadt *",
  doCountry: "Land *",
  doPayOption: "Ihre Zahlungsoption",
  doServiceTxt: "Bitte wählen Sie",
  doRedirectS:
    "Sie werden automatisch an das Stripe Payment Gateway System weitergeleitet",
  doDirectPay: "Direkte Bankzahlung an ALS Voice",
  doAlsAcc: "ALS Voice Spendenkonto",
  doCC: "Kreditkarte",
  doSubmit: "Einreichen",

  doValAmt: "Bitte geben Sie den Mindestbetrag von 55",
  doValSal: "Bitte Anrede auswählen ",
  doValName: "Bitte einen Wert eingeben",
  doValEmail: "E-Mail ist ungültig oder fehlt",
  doValStreet: "Straße fehlt",
  doValNr: "Nr ist ungültig oder fehlt",
  doValZip: "Postleitzahl ist ungültig oder fehlt",
  doValCity: "Stadt ist ungültig oder fehlt",
  doValCC:
    "Bitte wählen Sie mindestens 1 Zahlungsoption, die Sie bezahlen möchten",
  doVerTxt: "Bitte überprüfen Sie Ihre Angaben",
  doVerAmt: "Ihr gewählter Spendenbetrag:",
  doVerOpt: "Ihre gewählte Zahlungsmethode",
  doResTxt:
    "Verantwortliche Stelle: ALS Voice gGmbH. Die Verarbeitung erfolgt zum Zweck der ordnungsgemäßen Spendenabwicklung, der Finanzbuchhaltung, der Erfüllung von Aufgaben im öffentlichen Interesse und im berechtigten Interesse der ALS Voice gGmbH. Weitere Informationen finden Sie hier.",
  doStsTxt: "Ihr Zahlungsstatus:",
  doTnx: "Vielen Dank für Ihre Unterstützung!",
  doSuccessTxt: `Ihre Spende hilft uns, den Menschen eine Stimme zu geben die keine haben.
  Um eine Spendenbescheinigung zu erhalten, senden Sie bitte Ihre Spendenangaben an contact@alsvoice.org.
  Sie erhalten dann Ihre Spendenbescheinigung in Kürze.`,
  doWarmReg: "Herzliche Grüße und Dank von der ALS Voice Familie",
  doErrTxt: "Etwas ist schief gelaufen",
  doErrSbTxt:
    "Bitte versuchen Sie es erneut. Ihre Zahlung war nicht erfolgreich.",
  doErrCSTxt:
    "Ihre Spende hilft uns, den Menschen eine Stimme zu geben, die keine haben.",
  doDonation: "Spende",
  doBack: "Zurück",
  doHome: "Home",
  doSuccess: "Erfolg",
  doTransparency: "Transparenz",
  doTranTxt: `The ALS Voice sammelt Geld, um Patienten auf der ganzen Welt eine maßgeschneiderte,
  auf moderner Neurotechnologie basierende Kommunikationslösung zur Verfügung zu stellen.`,
  doHlp: "Ihre Spende hilft!",
  doDonr: "ALS Voice Spenderdienst",
  doFrm: "Spendenformular",
  doOvr: "Datenübersicht",
  doTransferred: "Überweisung der Spende",
  doMis: "Frau",
  doMr: "Herr",
  doFm: "Familie",
  doMrMs: "Herr & Frau",

  /**Speak Up */

  spChl: "Was ist #speakchallenge",
  spStoryElH: "Die Bedeutung der Kommunikation",
  spStoryEl:
    "Eine vollständige Lähmung hält den Menschen in seinem Körper gefangen, ohne dass er sich ausdrücken kann. Sie drängt den Einzelnen in einen Zustand der Ausgrenzung. Die #speakchallenge zielt darauf ab, das Bewusstsein für das Kommunikationsproblem vollständig gelähmter Menschen zu schärfen, und wird von ALS Voice organisiert.",
  spHowH: "Wie kann man an der #speakchallenge teilnehmen?",
  spStoryVH: "Die Geschichte von Valentina",
  spStoryV1:
    'Mein Bruder Fabio starb vor 2 Jahren. Er hatte ALS. Ich arbeite mit Ingenieuren, Wissenschaftlern und Psychologen aus der ganzen Welt zusammen. Wir haben eine gemeinnützige Organisation gegründet und werben für die #speakchallenge: "Was würdest du sagen, wenn du es nicht sagen könntest?"... das hat uns mein Bruder mit der Augenspur gesagt: ',
  spStoryVQt: "“Das Leben ist ein Geschenk... genießen Sie es!”.",
  spStoryV2: "Was werden Sie sagen?. Folgen Sie und helfen Sie uns.",
  spChStpH: "Die #SpeakChallenge ist da!",
  spSt1H: "TEILNAHME",
  spSt1Txt:
    'Nehmen Sie ein kleines Video auf oder schreiben Sie auf, "was Sie sagen würden, wenn Sie nicht sprechen könne".',
  spSt2H: "TEILEN",
  spSt2Txt:
    "Teilen Sie das aufgenommene Video oder den Text mit Ihren Freunden, Ihrer Familie, Ihren Verwandten oder anderen Personen, die Sie kennen, auf verschiedenen sozialen Medien und verwenden Sie den Hashtag #alsvoice #speakchallenge.",
  spSt3H: "EINLADEN",
  spSt3Txt:
    "Laden Sie Ihre Freunde, Verwandten und Familienmitglieder zur Teilnahme an der #speakchallenge ein.",
  spSt4H: "SPENDEN",
  spSt4Txt:
    "ALS Voice wird von einer kleinen Gruppe von Freiwilligen betrieben. Wenn Sie sich veranlasst fühlen, das Projekt zu unterstützen und Ihre Wertschätzung zeigen möchten, sind Sie herzlich eingeladen, zu spenden, indem Sie auf die Schaltfläche unten klicken.",
  spStoryElVideo: "4LywSrNwlHc",
};
