import React, { useState, useEffect } from "react";
import axios from "axios";
import useTranslation from "../../components/customHooks/translations";
import { BasicHeader } from "../../components/Header/BasicHeader";
import { Topbar } from "../../components/Navigations/Topbar";
import donate from "../../assets/img/headers/donate.png";
// import donateIcon from "../../assets/img/donate-icon.png";
// import stripePaymentIcon from "../../assets/img/stripe.png";
// import paymentPaypalIcon from "../../assets/img/payment-paypal.png";
import paymentMasterCardVisaIcon from "../../assets/img/payment-mastercard-visa.png";
import { Footer } from "../../components/Footer";
import countries from "i18n-iso-countries";
// Import the languages you want to use
import enLocale from "i18n-iso-countries/langs/en.json";
import itLocale from "i18n-iso-countries/langs/it.json";
// import classNames from "classnames";
import { Steps } from "../../components/Navigations/Steps";
import { Declarations } from "../../components/Declarations";
import { DataPreview } from "../../components/DataPreview";
import { PaymentStatus } from "../../components/PaymentStatus";

const Donate = () => {
  const t = useTranslation();
  // const BASE_URL = "http://localhost:5000";
  const BASE_URL = "https://api-alsvoice.herokuapp.com";
  const [stepCount, setStepCount] = useState(1);
  const [isGerman, setIsGerman] = useState(false);
  // const [validated, setValidated] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState("DE");

  const [amount, setAmount] = useState("");
  // const [purpose, setPurpose] = useState("");
  const [salutation, setSalutation] = useState("");
  const [title, setTitle] = useState("");
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [email, setEmail] = useState("");
  const [street, setStreet] = useState("");
  const [zip, setZip] = useState("");
  const [nr, setNr] = useState("");
  const [city, setCity] = useState("");
  const [currency, setCurrency] = useState("eur");
  const [paymentG, setPaymentG] = useState("");
  // const [formObj, setFormObj] = useState({});

  const [success, setSuccess] = useState(false);

  const [errors, setErrors] = useState([]);

  const selectCountryHandler = (value) => setSelectedCountry(value);

  // Have to register the languages you want to use
  countries.registerLocale(enLocale);
  countries.registerLocale(itLocale);

  // Returns an object not a list
  const countryObj = countries.getNames("en", { select: "official" });

  const countryArr = Object.entries(countryObj).map(([key, value]) => {
    return {
      label: value,
      value: key,
    };
  });

  // const handleSubmit = (event) => {
  //   const form = event.currentTarget;
  //   if (form.checkValidity() === false) {
  //     event.preventDefault();
  //     event.stopPropagation();
  //   }

  //   setValidated(true);
  // };
  const hasError = (key) => errors.indexOf(key) !== -1;

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log(`Amount :  ${amount}`);
    //VALIDATE
    const errors = [];
    //Amount
    if (parseInt(amount) < 55 || amount === "") errors.push("amount");
    //Salutation
    if (salutation === "") errors.push("salutation");
    //first name
    if (fname === "") errors.push("fname");
    //last name
    if (lname === "") errors.push("lname");
    //Payment Gateway
    if (paymentG === "") errors.push("paymentG");
    if (isGerman) {
      //Street
      if (street === "") errors.push("street");
      //Nr
      if (nr === "") errors.push("nr");
      //Zip
      if (zip === "") errors.push("zip");
      //City
      if (city === "") errors.push("city");
    }

    //email
    const expression = /\S+@\S+/;
    var validEmail = expression.test(String(email).toLowerCase());

    if (!validEmail) {
      errors.push("email");
    }

    setErrors(errors);

    if (errors.length > 0) {
      return false;
    } else {
      alert("everything good. submit form!");
      setStepCount(2);
    }
  };
  // const onChangeHandler = (fieldName, value)=>{
  //   if(fieldName === "amount"){
  //     setAmount(value);
  //   }
  //   else if(fieldName==="purpose"){
  //     setPurpose(value);
  //   }
  //   else if(fieldName==="salutation"){
  //     setSalutation(value);
  //   }
  //   else if(fieldName==="title"){
  //     setTitle(value);
  //   }
  //   else if(fieldName==="fname"){
  //     setFname(value);
  //   }
  //   else if(fieldName==="lname"){
  //     setLname(value);
  //   }
  //   else if(fieldName==="email"){
  //     setEmail(value);
  //   }
  // }
  const minmax = (value) => {
    if (isNaN(value)) {
      setAmount("");
      return;
    } else {
      setAmount(parseInt(value));
    }
  };

  const handleDonation = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        `${BASE_URL}/api/donation/create-checkout-session`,
        {
          amount,
          name: "General Donation",
          currency: currency.toLowerCase(),
          email,
          street,
          nr,
          zip,
          city,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      // console.log(response);
      if (response.status === 200) {
        // return <Redirect to={response.data.msg} />;
        window.location = response.data.msg;
      }

      if (response.data.success) {
        // console.log("Successful payment");
        setSuccess(true);
      }
    } catch (error) {
      console.log("Error", error);
      setStepCount(3);
      setSuccess(false);
    }
  };

  useEffect(() => {
    // Check to see if this is a redirect back from Checkout
    const query = new URLSearchParams(window.location.search);
    let session_id = query.get("session_id");
    if (!session_id) return;
    if (session_id.length > 40) {
      console.log(query.get("session_id"));
      setStepCount(3);
      setSuccess(true);
    }
    if (query.get("canceled")) {
      setStepCount(3);
      setSuccess(false);
    }
  }, []);

  return (
    <div>
      <Topbar />
      <BasicHeader
        title={t.dMkd.toUpperCase()}
        content={t.dHlp.toUpperCase()}
        bgurl={donate}
      />
      <Steps stepCount={stepCount} />
      <section style={{ display: stepCount === 1 ? "block" : "none" }}>
        <div className="container">
          <div className="row">
            <Declarations />
            <div className="col-lg-9 mx-auto">
              <div className="container donate-page">
                <form className="">
                  {/* className="was-validated"> */}
                  <h2 className="section-headline">
                    <span>{t.doTitle}</span>
                  </h2>
                  <div className="form-group">
                    <label for="amount">{t.doSubTitle}</label>
                    <select
                      id="currency"
                      value={currency}
                      onChange={(e) => setCurrency(e.target.value)}
                      className={
                        hasError("currency")
                          ? "form-control is-invalid"
                          : "form-control"
                      }
                    >
                      <option selected>EUR</option>
                      <option>USD</option>
                      <option>AUD</option>
                      <option>CAD</option>
                      <option>GBP</option>
                      <option>AED</option>
                      <option>CNY</option>
                      <option>RMB</option>
                      <option>BRL</option>
                    </select>
                    <input
                      type="text"
                      onChange={(e) => minmax(e.target.value)}
                      autoComplete="off"
                      className={
                        hasError("amount")
                          ? "form-control is-invalid"
                          : "form-control"
                      }
                      value={amount}
                      // onChange={(e)=>setAmount(e.target.value)}
                      id="amount"
                      placeholder={t.doPlaceHolder}
                      name="amount"
                    />
                    <div
                      className={
                        hasError("amount") ? "inline-errormsg" : "hidden"
                      }
                    >
                      {" "}
                      {t.doValAmt} {currency.toUpperCase()}{" "}
                    </div>
                  </div>
                  <h2 className="section-headline">
                    <span> {t.doYrDTitle} </span>
                  </h2>
                  <div className="form-row">
                    <div className="form-group col-md-12">
                      <label for="instruction">{t.doPersonalTle}</label>
                    </div>
                    <div className="form-group col-md-6">
                      <label for="salutation">{t.doSalutation}</label>
                      <select
                        id="salutation"
                        value={salutation}
                        onChange={(e) => setSalutation(e.target.value)}
                        className={
                          hasError("salutation")
                            ? "form-control is-invalid"
                            : "form-control"
                        }
                      >
                        <option selected>{t.doSelect}</option>
                        <option>{t.doMis}</option>
                        <option>{t.doMr}</option>
                        <option>{t.doFm}</option>
                        <option>{t.doMrMs}</option>
                      </select>
                      <div
                        className={
                          hasError("salutation") ? "inline-errormsg" : "hidden"
                        }
                      >
                        {" "}
                        {t.doValSal}
                      </div>
                    </div>
                    <div className="form-group col-md-6">
                      <label for="Title">{t.doTitle}</label>
                      <select
                        id="title"
                        className="form-control"
                        onChange={(e) => setTitle(e.target.value)}
                        value={title}
                      >
                        <option>{t.doSelect}</option>
                        <option>Prof.</option>
                        <option>Dr.</option>
                      </select>
                    </div>
                    <div className="form-group col-md-6">
                      <label for="fname">{t.doFname}</label>
                      <input
                        type="text"
                        autoComplete="off"
                        className={
                          hasError("fname")
                            ? "form-control is-invalid"
                            : "form-control"
                        }
                        value={fname}
                        onChange={(e) => setFname(e.target.value)}
                        id="fname"
                        placeholder={t.doFname}
                        name="fname"
                        required
                      />
                      <div
                        className={
                          hasError("fname") ? "inline-errormsg" : "hidden"
                        }
                      >
                        {" "}
                        {t.doValName}{" "}
                      </div>
                    </div>
                    <div className="form-group col-md-6">
                      <label for="fname">{t.doLname}</label>
                      <input
                        type="text"
                        autoComplete="off"
                        className={
                          hasError("lname")
                            ? "form-control is-invalid"
                            : "form-control"
                        }
                        value={lname}
                        onChange={(e) => setLname(e.target.value)}
                        id="lname"
                        placeholder={t.doLname}
                        name="lname"
                        required
                      />
                      <div
                        className={
                          hasError("lname") ? "inline-errormsg" : "hidden"
                        }
                      >
                        {" "}
                        {t.doValName}{" "}
                      </div>
                    </div>
                    <div className="form-group col-md-12">
                      <label for="email">{t.doEmail}</label>
                      <input
                        autoComplete="off"
                        className={
                          hasError("email")
                            ? "form-control is-invalid"
                            : "form-control"
                        }
                        name="email"
                        id="email"
                        placeholder={t.doEmail}
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                      <div
                        className={
                          hasError("email") ? "inline-errormsg" : "hidden"
                        }
                      >
                        {t.doValEmail}
                      </div>
                    </div>
                  </div>
                  <div className="form-group" style={{ margin: "2rem 0" }}>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="gridCheck"
                        onChange={(e) => {
                          setIsGerman(!isGerman);
                        }}
                      />
                      <label
                        className="form-check-label"
                        for="gridCheck"
                        style={{ margin: "0" }}
                      >
                        {t.doCertTitle}
                      </label>
                    </div>
                  </div>

                  <div className={`form-row ${isGerman ? "" : "d-none"}`}>
                    <div className="form-group col-md-8">
                      <label for="street">{t.doStreet}</label>
                      <input
                        type="text"
                        autoComplete="off"
                        className={
                          hasError("street")
                            ? "form-control is-invalid"
                            : "form-control"
                        }
                        name="street"
                        id="street"
                        placeholder={t.doStreet}
                        value={street}
                        onChange={(e) => setStreet(e.target.value)}
                      />
                      <div
                        className={
                          hasError("nr") ? "inline-errormsg" : "hidden"
                        }
                      >
                        {t.doValStreet}
                      </div>
                    </div>

                    <div className="form-group col-md-4">
                      <label for="nr">{t.doNr}</label>
                      <input
                        type="text"
                        autoComplete="off"
                        className={
                          hasError("nr")
                            ? "form-control is-invalid"
                            : "form-control"
                        }
                        name="nr"
                        id="nr"
                        placeholder={t.doNr}
                        value={nr}
                        onChange={(e) => setNr(e.target.value)}
                      />
                      <div
                        className={
                          hasError("nr") ? "inline-errormsg" : "hidden"
                        }
                      >
                        {t.doValNr}
                      </div>
                    </div>
                    <div className="form-group col-md-8">
                      <label for="zip">{t.doZip}</label>
                      <input
                        type="text"
                        autoComplete="off"
                        className={
                          hasError("zip")
                            ? "form-control is-invalid"
                            : "form-control"
                        }
                        name="zip"
                        id="zip"
                        placeholder={t.doZip}
                        value={zip}
                        onChange={(e) => setZip(e.target.value)}
                      />
                      <div
                        className={
                          hasError("zip") ? "inline-errormsg" : "hidden"
                        }
                      >
                        {t.doValZip}
                      </div>
                    </div>
                    <div className="form-group col-md-4">
                      <label for="city">{t.doCity}</label>
                      <input
                        type="text"
                        autoComplete="off"
                        className={
                          hasError("city")
                            ? "form-control is-invalid"
                            : "form-control"
                        }
                        name="city"
                        id="city"
                        placeholder={t.doCity}
                        value={city}
                        onChange={(e) => setCity(e.target.value)}
                      />
                      <div
                        className={
                          hasError("city") ? "inline-errormsg" : "hidden"
                        }
                      >
                        {t.doValCity}
                      </div>
                    </div>

                    <div className="form-group col-md-12">
                      <label for="country">{t.doCountry}</label>
                      <select
                        className="form-control"
                        value={selectedCountry}
                        onChange={(e) => selectCountryHandler(e.target.value)}
                      >
                        {!!countryArr?.length &&
                          countryArr.map(({ label, value }) => (
                            <option key={value} value={value}>
                              {label}
                            </option>
                          ))}
                      </select>
                    </div>
                  </div>
                  <h2 className="section-headline">
                    <span>{t.doPayOption} </span>
                  </h2>
                  <div className="form-group col-md-12">
                    <label for="instruction">{t.doServiceTxt}</label>
                  </div>
                  <div className="container donate-page">
                    <div className="row">
                      {/* <div className="col-sm-6">
                        <div className="card">
                          <div className="card-body d-flex pt-0">
                            <h5 className="card-title">
                              <label for="payment-g">
                                <input
                                  type="radio"
                                  name="payment-g"
                                  value="paypal"
                                  checked={paymentG === "paypal"}
                                  onChange={(e) => setPaymentG(e.target.value)}
                                />
                                <span>PayPal</span>
                              </label>
                              <p className="card-text text-muted f-14">
                                You will be automatic passed on to PayPal
                              </p>
                            </h5>
                            <img className="selected" src={paymentPaypalIcon} />
                          </div>
                        </div>
                      </div> */}
                      <div className="col-sm-6">
                        <div className="card">
                          <div className="card-body d-flex pt-0">
                            <h5 className="card-title">
                              <label for="payment-g">
                                <input
                                  type="radio"
                                  name="payment-g"
                                  value="credit-card"
                                  checked={paymentG === "credit-card"}
                                  onChange={(e) => setPaymentG(e.target.value)}
                                />
                                <span>{t.doCC}</span>
                              </label>
                              <p className="card-text text-muted f-14">
                                {t.doRedirectS}
                              </p>
                            </h5>
                            <img
                              className="selected"
                              src={paymentMasterCardVisaIcon}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-12 text-xl-center">
                        <div
                          className={
                            hasError("paymentG") ? "inline-errormsg" : "hidden"
                          }
                        >
                          {" "}
                          {t.doValCC}{" "}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-5">
                    <div className="col-sm-6"></div>
                    <div className="col-sm-6 text-right">
                      <a
                        href="#"
                        onClick={(e) => handleSubmit(e)}
                        className="next pre"
                      >
                        {t.doSubmit} &raquo;
                      </a>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>

      <DataPreview
        stepCount={stepCount}
        onBackHandler={() => setStepCount(1)}
        amount={amount}
        email={email}
        fname={fname}
        lname={lname}
        onHandleDonation={handleDonation}
      />
      <PaymentStatus
        success={success}
        stepCount={stepCount}
        onBackHandler={() => setStepCount(1)}
      />
      <div className="container">
        <div className="row">
          <div className="col-2"></div>
          <div className="col-10">
            <div
              className="card h-100 justify-content-center"
              style={{ border: "none" }}
            >
              <h2 className="section-headline">
                <span> {t.doDirectPay} </span>
              </h2>
              <div className="card-body">
                <h3 className="card-title">{t.dAlsD}</h3>
                <p className="card-text-a">
                  <ul>
                    <li>IBAN: DE48 6407 0024 0137 2515 00</li>
                    <li>BIC: DEUTDEDB640</li>
                  </ul>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Donate;
