import React, { useState, useEffect} from 'react';
import useTranslation from "../../components/customHooks/translations";

import { Topbar } from '../../components/Navigations/Topbar';
import { Footer } from '../../components/Footer';

import step1 from '../../assets/img/step-1.png'
import step2 from '../../assets/img/step-2.png'
import step3 from '../../assets/img/step-3.png'
import step4 from '../../assets/img/step-4.png'

import EnServices from '../../assets/img/services-en.png'
import DeServices from '../../assets/img/services-de.png'
import ItServices from '../../assets/img/services-it.png'

const Services = ()=> {
    const t = useTranslation();
    const [service, setService] = useState(EnServices);
    useEffect(() => {
        if(t._language === 'de'){
            setService(DeServices)
        }else if(t._language === 'it'){
            setService(ItServices)
        }else{
            setService(EnServices)
        }
      
    }, [t])
  return (
    <div>
    <Topbar/>
    <div className="container-fluid mt-3">
        <div className="row">
            <div className="col">
                <div className="center marginTop50 ">
                    <div className="card no-background">
                        <div className="card-body">
                            <p className="card-text header">{t.hiwHeader}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div className="container h-100">
        <div className="row h-100">
            <div className="col-12">
                <div className="card h-100">
                    <div className="card-body" style={{padding:0}}>
                        {/* <h3 className="card-title">{t.navHow}</h3> */}
                        <p className="card-text-a">{t.hiwDesc}</p>
                        <p className="card-text-a pt-2">{t.hiwPntHdr}</p>
                        <p className="card-text-a">
                        <ul >
                            <li>{t.hiwPnt1}</li>
                            <figure className="work">
                                <img src={step1} alt="consultation and assessment" className="img-border"/>
                                    <figcaption>{t.hiwFig1}</figcaption>
                                </figure>
                            <li>{t.hiwPnt2}</li>
                            <figure className="work">
                                <img src={step2} alt="communication without the help of a professional" className="img-border"/>
                                    <figcaption>{t.hiwfig2}</figcaption>
                            </figure >

                            <div className="col-12 pt-2 pb-2">
                            <div className="card h-100 justify-content-center">
                                <div className="card-body">
                                    <img src={service} alt="Lights" style={{width: '100%'}}/>
                                   
                                </div>
                            </div>
                        </div> 
                            <li>{t.hiwPnt3}</li>
                            <figure className="work">
                                <img src={step3} alt="regular assessment" className="img-border"/>
                                <figcaption>{t.hiwfig3}</figcaption>
                            </figure>
                            <li>{t.hiwPnt4}</li>
                            <figure className="work">
                                <img src={step4} alt="hardware and the software" className="img-border"/>
                                <figcaption>{t.hiwfig4}</figcaption>
                            </figure>
                            </ul>
                        </p>
                        <p className="card-text-a pb-2">{t.hiwEnd}</p>
                    </div>
                </div>
            </div>
           
        </div>
    </div>
    <Footer/>
</div>
  )
}

export default Services