import React, { useState, useEffect } from "react";
import useTranslation from "../../components/customHooks/translations";

import { Topbar } from "../../components/Navigations/Topbar";
import { Footer } from "../../components/Footer";

import m1 from "../../assets/img/media/m-1.jpg";
import m2 from "../../assets/img/media/m-2.jpg";
import m3 from "../../assets/img/media/m-3.jpg";
import m4 from "../../assets/img/media/m-4.jpg";
import m5 from "../../assets/img/media/m-5.jpg";
import m6 from "../../assets/img/media/m-6.jpg";
import m7 from "../../assets/img/media/m-7.jpg";
import m8 from "../../assets/img/media/m-8.jpg";
import m9 from "../../assets/img/media/m-9.jpg";
import m10 from "../../assets/img/media/m-10.jpg";
import m11 from "../../assets/img/media/m-11.jpg";
import m12 from "../../assets/img/media/m-12.jpg";

const mediaList = [
  {
    url: "https://www.nytimes.com/2022/03/22/science/brain-computer-als-communication.html",
    imgUrl: m1,
    name: "The New York Times",
  },
  {
    url: "https://www.theguardian.com/science/2022/mar/22/emotional-moment-locked-in-patient-communicates-with-family-via-implant",
    imgUrl: m2,
    name: "The Guardian",
  },
  {
    url: "https://www.cbc.ca/radio/asithappens/as-it-happens-thursday-edition-1.6396125/brain-implant-allows-paralyzed-man-to-communicate-says-study-1.6396131",
    imgUrl: m3,
    name: "CBC News",
  },
  {
    url: "https://cosmosmagazine.com/science/motor-neuron-disease-communication/",
    imgUrl: m4,
    name: "Cosmos Magazine",
  },
  {
    url: "https://california18.com/brain-implant-allows-totally-paralyzed-patient-to-communicate/3910422022/",
    imgUrl: m5,
    name: "California 18",
  },
  {
    url: "https://rennes.maville.com/actu/actudet_-en-allemagne-un-homme-paralyse-communiquait-par-la-pensee-_54135-5162249_actu.Htm",
    imgUrl: m6,
    name: "Rennes Maville",
  },
  {
    url: "https://theparadise.ng/how-brain-implant-led-fully-paralyzed-patient-to-communicate-in-game-changing-experiment/",
    imgUrl: m7,
    name: "The Paradise",
  },
  {
    url: "https://germanic.news/ein-eingeschlossener-mensch-hat-allein-durch-gedanken-in-satzen-kommuniziert/",
    imgUrl: m8,
    name: "Germanic News",
  },
  {
    url: "https://www.technologyreview.com/2022/03/22/1047664/locked-in-patient-bci-communicate-in-sentences/",
    imgUrl: m9,
    name: "MIT Technology Review",
  },
  {
    url: "https://www.diepta.de/news/locked-in-syndrom-kommunikation-ohne-einen-einzigen-muskel#",
    imgUrl: m10,
    name: "Diepta",
  },
  {
    url: "https://sleekgist.com/paralysed-man-can-ask-for-beer-with-just-his-mind-using-microchip-brain-implant/",
    imgUrl: m11,
    name: "Sleekgist",
  },
  {
    url: "https://www.science.org/content/article/first-brain-implant-lets-man-complete-paralysis-spell-out-thoughts-i-love-my-cool-son",
    imgUrl: m12,
    name: "Science",
  },
];

const Media = () => {
  const t = useTranslation();
  return (
    <div>
      <Topbar />
      <div className="container-fluid mt-3">
        <div className="row">
          <div className="col">
            <div className="center marginTop50 ">
              <div className="card no-background">
                <div className="card-body">
                  <p className="card-text header">{t.meHeader}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          {/* <div className="col-md-4">
            <div className="thumbnail">
              <a href={m1} target="_blank">
                <img src={m1} alt="Lights" style={{ width: "100%" }} />
                <div className="caption">
                  <p>
                    Lorem ipsum donec id elit non mi porta gravida at eget
                    metus.
                  </p>
                </div>
              </a>
            </div>
          </div> */}

          {mediaList.map(({ url, imgUrl, name }, index) => (
            <div className="col-md-4" key={index}>
              <div className="thumbnail">
                <a href={url} target="_blank">
                  <img
                    className="img-thumbnail"
                    src={imgUrl}
                    alt="Lights"
                    style={{ width: "100%", height: "13rem" }}
                  />
                  <div className="caption">
                    <p style={{ textAlign: "center" }}>{name}</p>
                  </div>
                </a>
              </div>
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Media;
