import React from "react";
import useTranslation from "../customHooks/translations";
// import microPaymentIcon from "../../assets/img/micro-payment.png";
import { Declarations } from "../Declarations";
// import paymentPaypalIcon from "../../assets/img/payment-paypal.png";
// import paymentMasterCardVisaIcon from "../../assets/img/payment-mastercard-visa.png";
export const PaymentStatus = ({ stepCount, onBackHandler, success }) => {
  const t = useTranslation();
  // console.log(stepCount);
  return (
    <section style={{ display: stepCount === 3 ? "block" : "none" }}>
      <div className="container">
        <div className="row">
          <Declarations />
          <div className="col-lg-9 mx-auto">
            <div className="container donate-page">
              <form className="">
                <h2 className="section-headline">
                  <span>{t.doStsTxt}</span>
                </h2>
                {success && (
                  <div className="success-card">
                    <div className="status-card-wrap">
                      <i className="checkmark">✓</i>
                    </div>
                    <h1 className="success-msg">{t.doSuccess}</h1>
                    <p style={{ textAlign: "justify" }}>
                      {t.doTnx}
                      <br /> {t.doSuccessTxt}
                      <br />
                      {t.doWarmReg}
                    </p>
                  </div>
                )}
                {!success && (
                  <div className="success-card">
                    <div className="status-card-wrap">
                      <i
                        className="checkmark-error fa fa-exclamation-triangle"
                        aria-hidden="true"
                      ></i>
                    </div>
                    <h1 className="error-msg">{t.doErrTxt}</h1>
                    <p style={{ textAlign: "justify" }}>
                      {t.doErrSbTxt}
                      <br />
                      {t.doErrCSTxt}
                      <br />
                      {t.doWarmReg}
                    </p>
                  </div>
                )}
                <div className="row">
                  <div className="col-sm-6">
                    <a
                      href="#"
                      onClick={() => onBackHandler()}
                      className="next pre"
                    >
                      &laquo; {t.doHome}
                    </a>
                  </div>
                </div>
                <div className="form-group">
                  <p className="responsibility">{t.doResTxt}</p>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
