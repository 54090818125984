import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import axios from "axios";
import { Topbar } from "../../components/Navigations/Topbar";
import { Footer } from "../../components/Footer";

import useTranslation from "../../components/customHooks/translations";

const Contactus = () => {
  const [responseMsg, setResponseMsg] = useState("");
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [values, setValues] = useState({
    firstName: "",
    lastName: "",
    email: "",
    message: "",
  });

  const t = useTranslation();
  // const BASE_URL = "http://localhost:5000";
  const BASE_URL = "https://api-alsvoice.herokuapp.com";
  const onSubmitHandler = async (e) => {
    e.preventDefault();
    let currentCode = localStorage.getItem("code") || "gb";
    try {
      console.log(values);
      if (values.email && values.message) {
        // POST request using axios with async/await
        const { isError, message } = await axios.post(
          `${BASE_URL}/api/contact/user/${currentCode}`,
          values
        );
        setResponseMsg(message);
        setSuccess(!isError);
      }
    } catch (error) {
      setError(true);
    }
  };
  const handleFirstNameInputChange = (event) => {
    event.persist();
    setValues((values) => ({
      ...values,
      firstName: event.target.value,
    }));
  };
  const handleLastNameInputChange = (event) => {
    event.persist();
    setValues((values) => ({
      ...values,
      lastName: event.target.value,
    }));
  };

  const handleEmailInputChange = (event) => {
    event.persist();
    setValues((values) => ({
      ...values,
      email: event.target.value,
    }));
  };
  const handleMessageInputChange = (event) => {
    event.persist();
    setValues((values) => ({
      ...values,
      message: event.target.value,
    }));
  };
  return (
    <div>
      <Topbar />
      <div className="container-fluid">
        <div className="row">
          <div className="col">
            <div className="center marginTop50 ">
              <div className="card no-background">
                <div className="card-body">
                  <p className="card-text header">{t.navContact}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        {success && (
          <div className=" text-center success-messages">{t.cuSuccess}</div>
        )}
        {error && (
          <div className=" text-center error-messages">{t.cuError}</div>
        )}
        {!success && (
          <div className="row ">
            <div className="col-lg-7 mx-auto">
              <div className="card mx-auto p-4 bg-light">
                <div className="card-body bg-light">
                  <div className="container">
                    <form id="contact-form">
                      <div className="controls">
                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group">
                              {" "}
                              <label for="form_name">{t.cuFname} </label>
                              <input
                                id="form_name"
                                type="text"
                                name="name"
                                className="form-control"
                                value={values.firstName}
                                onChange={handleFirstNameInputChange}
                                placeholder={t.cuFnameP}
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              {" "}
                              <label for="form_lastname">{t.cuLname} </label>
                              <input
                                id="form_lastname"
                                type="text"
                                name="surname"
                                className="form-control"
                                value={values.lastName}
                                onChange={handleLastNameInputChange}
                                placeholder={t.cuLnameP}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group">
                              {" "}
                              <label for="form_email">{t.cuEmail}</label>
                              <input
                                id="form_email"
                                type="email"
                                name="email"
                                className="form-control"
                                value={values.email}
                                onChange={handleEmailInputChange}
                                placeholder={t.cuEmailP}
                                required="required"
                                data-error={t.cuEmailE}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-12">
                            <div className="form-group">
                              <label for="form_message">{t.cuMsg}</label>
                              <textarea
                                id="form_message"
                                minLength={5}
                                maxLength={1000}
                                name="message"
                                className="form-control"
                                value={values.message}
                                onChange={handleMessageInputChange}
                                placeholder={t.cuMsgP}
                                rows="4"
                                required="required"
                                data-error={t.cuMsgE}
                              ></textarea>
                            </div>
                          </div>
                          <div className="col-md-12">
                            <input
                              type="submit"
                              className="btn btn-success btn-send pt-2 btn-block "
                              onClick={onSubmitHandler}
                              value={t.cuSend.toUpperCase()}
                            />
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
};

export default Contactus;
