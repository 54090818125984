import React from "react";
import useTranslation from "../customHooks/translations";
import { Declarations } from "../../components/Declarations";
// import paymentPaypalIcon from "../../assets/img/payment-paypal.png";
import paymentMasterCardVisaIcon from "../../assets/img/payment-mastercard-visa.png";
export const DataPreview = ({
  stepCount,
  onBackHandler,
  amount,
  email,
  fname,
  lname,
  onHandleDonation,
}) => {
  const t = useTranslation();
  console.log(stepCount);
  return (
    <section style={{ display: stepCount === 2 ? "block" : "none" }}>
      <div className="container">
        <div className="row">
          <Declarations />
          <div className="col-lg-9 mx-auto">
            <div className="container donate-page">
              <form action="/action_page.php" className="">
                {/* className="was-validated"> */}
                <h3 className="section-headline">
                  <span>{t.doVerTxt}</span>
                </h3>
                <h2 className="section-headline">
                  <span>{t.doTitle}</span>
                </h2>
                <div className="form-group">
                  <label for="uname">
                    {t.doVerAmt} <span id="d-amount">{amount}</span>
                    <span id="d-currency">Euro</span>{" "}
                  </label>
                </div>
                {/*  <h2 className="section-headline">
                      <span> Your donation purpose </span>
                    </h2>
                    <div className="form-group">
                    <label for="uname">Your chosen donation purpose: <span id="d-purpose">Emergency relief Ukraine</span></label>
                    </div> */}
                <h2 className="section-headline">
                  <span>{t.doPayOption} </span>
                </h2>
                <div className="container">
                  <div className="row">
                    <div className="col-sm-6">
                      <label for="instruction">{t.doVerOpt}</label>
                    </div>
                    <div className="col-sm-6">
                      <div className="card bg-transparent text-dark">
                        <div className="card-body d-flex">
                          <h5 className="card-title">
                            <p className="card-text text-muted f-14">
                              {t.doRedirectS}
                            </p>
                            <p className="card-text text-muted f-14 d-flex flex-column justify-content-between">
                              <span>
                                {fname} {lname}
                              </span>
                              <span>{email}</span>
                              {/* <span> 521510******2917</span> */}
                            </p>
                          </h5>
                          <img
                            className="selected"
                            src={paymentMasterCardVisaIcon}
                            alt="Payment Option"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-5">
                    <div className="col-sm-6">
                      <a
                        href="#"
                        onClick={() => onBackHandler()}
                        className="previous pre"
                      >
                        &laquo; {t.doBack}
                      </a>
                    </div>
                    <div className="col-sm-6 text-right">
                      <a
                        href="#"
                        className="next pre"
                        onClick={onHandleDonation}
                      >
                        {t.doDonation} &raquo;
                      </a>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <p className="responsibility">{t.doResTxt}</p>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
