export default {
  homePara:
    "Language localisation is the process of adapting a product's translation to a specific country or region. It is the second phase of a larger process of product translation and cultural adaptation to account for differences in distinct markets, a process known as internationalisation and localisation.",
  aboutPara:
    "This tutorial is targetted to make localization simple. Though there are a lot of tutorials available on the internet. But, this one will teach you the concept in a simple manner. You can directly use this code for your software. I have used 'Rreact' hooks which is software industry standard now. You can follow my blog to get more UI related articles which will make you a better 'UI' developer. {0}",
  changeLanguage: "Change your langauge",
  name: "Name",
  age: "Age",
  address: "Address",
  contactNo: "Contact No",
  save: "Save",
  formPara: "Please, enter your personal details here.",
  dynamicValue: "Dynamic Value- {randomNumber} {date}",

  //Header Text..
  navHow: "Come funziona",
  navTec: "Tecnologia",
  navServ: "Servizi",
  navVisi: "Vision",
  navScr: "Screening",
  navVolu: "Volontario",
  navDon: "Donare",
  navContact: "Contattateci",
  navMedia: "Media",
  //Landing Page
  //Section 1.
  npo: "Un'iniziativa no-profit",
  npoCap:
    "Soluzioni di comunicazione guidate dalle neurotecnologie e dall'intelligenza artificiale",
  pscs: "Soluzione di comunicazione personalizzata",
  commPara: "Soluzione di comunicazione personalizzata",
  //Section 2. Card Header & Caption
  step1: "Consultazione",
  step1Para: "Valutare la disabilità di comunicazione.",
  step2: "Strategia di comunicazione personalizzata",
  step2Para:
    "Sviluppo di una soluzione personalizzata utilizzando i segnali del cervello e dei muscoli.",
  step3: "Formazione e ottimizzazione",
  step3Para:
    "Formazione e valutazione delle prestazioni di comunicazione. Adattamento dell'hardware e del software al cambiamento dello stato dell'individuo.",
  step4: "Supporto continuo",
  step4Para:
    "Un esperto per facilitare la comunicazione secondo il bisogno dell'individuo e della sua famiglia.",
  learnM: "Per saperne di più",

  problem: "Perdere la capacità di comunicare",
  problemStmt:
    'Molti individui perdono la capacità di parlare a causa di sclerosi laterale amiotrofica (SLA), sclerosi multipla (SM), ictus, lesioni cerebrali da trauma cranico ed altri disturbi.  Quando un paziente può usare solo i propri occhi per comunicare, mentre il resto del corpo è paralizzato, si considera in stato di "locked-in". Nel caso della SLA, il disturbo della maggior parte degli individui progredisce fino a perdere il controllo dei muscoli dell\'occhio, per arrivare ad uno stato detto di "complete locked-in". In questi casi usualmente gli individui rimangono senza mezzi di comunicazione, quindi incapaci di esprimere i loro bisogni e desideri.',
  solution: "Forniamo",
  solutionStmt:
    "Consultare, sostenere e permettere la comunicazione utilizzando la neurotecnologia in uno stato così critico.",

  wePrd: "",
  how: "Il nostro motto",
  neroTech:
    "Noi di ALS Voice, basandoci sui nostri anni di ricerca e sviluppo, ci sforziamo di fornire un mezzo personalizzato di servizi di comunicazione agli individui utilizzando tecnologie neuro e biomediche all'avanguardia.",

  // Section 3.
  //Header & captions
  oVal: "",
  commHr: "Comunicazione per tutti",
  commDesc:
    "Essere in grado di esprimere se stessi dovrebbe essere disponibile per tutti, indipendentemente dall'accessibilità finanziaria",
  experHead: "Espandere le esperienze di vita",
  expPara:
    "Serviamo fornendo un'intelligenza artificiale (AI) all'avanguardia, alimentata da tecnologie biomediche e neurologiche personalizzate per la comunicazione",
  selfExpr: "Rafforzare l'auto-espressione",
  selfExprPara:
    "Permettiamo alle persone con deficit di comunicazione di esprimere la loro voce",
  /** End of how it work page */

  //Footer Page
  ftFindUs: "TROVACI SU:",
  ftNewsLttr: "ABBONARSI NEWSLETTER:",
  ftNeverShr: "Non condivideremo mai la tua email con nessun altro.",
  ftPrivacy: "Politica sulla privacy",
  ftPolicy: "Impronta",
  ftSubcr: "Ti sei iscritto con successo.",

  // Content for the footnote:
  ftNotes:
    "Siamo un'organizzazione senza scopo di lucro e di beneficenza: L'ufficio delle imposte di Tubinga ha accertato separatamente il rispetto dei requisiti di legge ai sensi dei §§ 51, 59, 60 e 61 AO in una comunicazione del 04-feb-2022 ai sensi del § 60a AO.  Ai sensi del nostro statuto, promuoviamo l'assistenza ai disabili, l'assistenza sociale, in particolare gli scopi delle associazioni di volontariato ufficialmente riconosciute (§ 23 dell'ordinanza di attuazione dell'imposta sul valore aggiunto), le loro sotto-associazioni e le loro strutture e istituzioni affiliate, nonché gli scopi di beneficenza, e siamo autorizzati ad accettare donazioni e a rilasciare ricevute di donazione per questi scopi.",
  /** End of Footer */

  //How it Work page
  //Header :
  meHeader: "Copertura mediatica della possibilità di comunicazione",
  hiwHeader: "Soluzione di comunicazione personalizzata",
  hiwDesc:
    'Molti individui perdono la capacità di parlare a causa di sclerosi laterale amiotrofica (SLA), sclerosi multipla (SM), ictus, lesioni cerebrali da trauma cranico ed altri disturbi.  Quando un paziente può usare solo i propri occhi per comunicare, mentre il resto del corpo è paralizzato, si considera in stato di "locked-in". Nel caso della SLA, il disturbo della maggior parte degli individui progredisce fino a perdere il controllo dei muscoli dell\'occhio, per arrivare ad uno stato detto di "complete locked-in". In questi casi usualmente gli individui rimangono senza mezzi di comunicazione, quindi incapaci di esprimere i loro bisogni e desideri.',
  hiwPntHdr:
    "Forniamo a questi individui servizi di comunicazione personalizzati dove offriamo:",
  //Points
  hiwPnt1:
    "Consultazione e valutazione per valutare il problema e determinare l'approccio e la soluzione di comunicazione appropriata.",
  hiwPnt2:
    "Soluzione di comunicazione personalizzata utilizzando la neurotecnologia in uno stato così critico.",
  hiwPnt3:
    "Un esperto per facilitare la comunicazione secondo il bisogno dell'individuo e della sua famiglia e gradualmente uno o più membri della famiglia saranno addestrati anche per permettere la comunicazione senza l'aiuto di un professionista.",
  hiwPnt4:
    "Una valutazione regolare delle prestazioni di comunicazione, un adattamento dell'hardware e del software allo stato mutevole dell'individuo e un supporto continuo.",
  hiwEnd:
    "Così, ALS Voice fornisce consulenza, supporto e assistenza end-to-end per consentire la comunicazione utilizzando una tecnologia all'avanguardia che impiega il biosegnale dai diversi muscoli del corpo o il segnale cerebrale dalla parte superiore dello scalpo e, infine, il segnale neurale dall'impianto cerebrale.",
  //Figure captions
  hiwFig1: "",
  hiwfig2: "",
  hiwfig3: "",
  hiwfig4: "",

  // Technology
  //Section 1.
  //Header
  tHeader:
    "Interfaccia cervello-computer (BCI) / Interfaccia uomo-computer (HCI)",
  tH1: "Il corpo umano è costituito da intricate connessioni e reti di muscoli, nervi, vasi sanguigni e ossa che lavorano insieme per creare ogni individuo.",
  tH2: "Gli sviluppi e i progressi scientifici ci permettono di misurare e registrare diversi tipi di segnali dal nostro corpo. Abbiamo la capacità di autoregolare alcuni di questi segnali, come ad esempio l'elettroencefalogramma (EEG), l'elettrooculogramma (EOG), l'elettromiogramma (EMG), l'elettrocorticogramma (ECoG), e dei segnali neurali (NS).",

  teeg: "EEG - L'elettroencefalogramma (EEG) misura l'attività elettrica dalla superficie dello scalpo.",
  teog: "EOG - L'elettrooculografia (EOG) misura l'attività elettrica dovuta al movimento degli occhi.",
  temg: "EMG - L'elettromiografia (EMG) misura l'attività elettrica delle diverse parti del corpo dovuta al movimento muscolare.",
  tcog: "ECoG - L'elettrocorticografia (ECoG) misura l'attività elettrica dalla superficie esterna del cervello.",
  tnus: "Segnali neurali - I segnali neurali (NS) sono il segnale acquisito dai neuroni nel cervello.",
  //tcog: "ECoG – Electrocorticography measures electrical activity from the outer surface of brain.",
  //tnirs: "NIRS – Near-infrared spectroscopy (NIRS) measures the change in oxygen content of blood in a given region of the brain from the surface of the scalp.",
  //tfmri: "fMRI – Functional magnetic resonance imaging (fMRI) measures brain activity by measuring blood oxygen level dependent change in the brain.",

  tibci:
    "C'è una tecnologia emergente chiamata interfaccia cervello-computer (BCI)/ interfaccia uomo-computer (HCI), dove un individuo può controllare i dispositivi esterni usando i segnali del corpo di cui sopra.",
  tnbci:
    "Un individuo può essere addestrato a cambiare questi diversi segnali del corpo per produrre un segnale specifico per un compito eseguito da un individuo. Questi segnali specifici del compito possono essere elaborati da un algoritmo di elaborazione dei segnali e da un algoritmo di intelligenza artificiale per creare modelli e comandi per controllare un dispositivo esterno.",
  tabb2:
    "Sulla base di questi principi, gli individui con deficit di comunicazione possono essere addestrati a usare un sistema di comunicazione esterno con la vista o l'udito. Pertanto, un individuo con un deficit di comunicazione può utilizzare la tecnologia BCI/HCI per comunicare i propri desideri e le proprie esigenze utilizzando solo i sensi della vista o dell'udito.",
  tend1:
    "Così, forniamo soluzioni hardware e software personalizzate per l'interfaccia cervello-computer (BCI) / interfaccia uomo-computer (HCI) che permetterà a un individuo di controllare dispositivi esterni per la comunicazione utilizzando i segnali del corpo di cui sopra.",

  tlHeader: "Neurotecnologia: La prossima frontiera della comunicazione",
  tlReadSig: "Lettura dei segnali del corpo e del cervello",
  tlReadSigDes:
    "Gli sviluppi e i progressi scientifici ci permettono di misurare e registrare diversi tipi di segnali dal nostro corpo. Le persone hanno la capacità di autoregolare alcuni di questi segnali, vale a dire elettroencefalogramma (EEG), elettrooculogramma (EOG), elettromiogramma (EMG), elettrocorticogramma (ECoG), e segnali neurali (NS), tra alcuni altri.",
  tlibci:
    "C'è una tecnologia emergente chiamata interfaccia cervello-computer (BCI)/ interfaccia uomo-computer (HCI), dove un individuo può controllare dispositivi esterni (computer, macchine, oggetti) usando i segnali del corpo di cui sopra.",
  tlComm: "Comunicazione usando i segnali del corpo e del cervello",
  tlCommDes1:
    "Un individuo può essere addestrato a cambiare questi diversi segnali del corpo per produrre segnali. Per i dispositivi esterni, questo si chiama biofeedback e neurofeedback.",
  tlCommDes2:
    "Il segnale specifico del compito viene elaborato da algoritmi di elaborazione del segnale e di intelligenza artificiale. L'apprendimento specifico del compito migliora la tecnologia di comunicazione.",
  tlCommDes3:
    "Questi algoritmi mappano i modelli specifici del compito dei segnali registrati per generare comandi per controllare un dispositivo esterno.",
  tlCommDes4:
    "In questo modo, un individuo con deficit di comunicazione può essere addestrato a utilizzare un sistema di comunicazione esterno anche se completamente paralizzato. Così, un individuo con un deficit di comunicazione può usare la tecnologia BCI/HCI per comunicare i suoi desideri e le sue volontà usando solo la vista o l'udito.",

  /** End of technology page */

  // vision
  //Section 1.
  //Header
  vHeader: "Soluzione di comunicazione personalizzata",
  vPara1:
    "È inimmaginabile vivere senza comunicazione con il mondo sempre più ristretto e connesso. Anche se diversi sistemi sono disponibili in commercio per servire come un aiuto alla comunicazione per gli individui senza mezzi di comunicazione, molti non possono usare questi sistemi e sono quindi lasciati senza alcun mezzo di comunicazione.",
  vPara2:
    "Stiamo proponendo un servizio unico per servire questa popolazione non servita. L'organizzazione mira a fornire soluzioni di comunicazione personalizzate agli individui che non hanno alcun mezzo di comunicazione. La nostra organizzazione darà consulenza, supporto e assistenza end-to-end per consentire la comunicazione utilizzando il segnale fisiologico dai diversi muscoli del corpo o il segnale cerebrale dalla parte superiore del cuoio capelluto e, infine, il segnale neurale dall'impianto cerebrale. Non stiamo fornendo alcun trattamento medico né stiamo curando alcun disturbo.",
  vPara3:
    "Ci sforzeremo di sostenere il desiderio delle persone e dei loro familiari di comunicare tra loro. Il motto è permettere la comunicazione. Lavoreremo con voi per tutto il tempo che desiderate e vi forniremo un mezzo di comunicazione utilizzando la più avanzata tecnologia biomedica e neurotecnologica basata sull'intelligenza artificiale.",
  /** End of vision page */

  // Screening
  //Section 1.
  //Header
  sHeader: "Condizione e mezzi di comunicazione",
  sQ1: "1. Seleziona la condizione",
  sQ1O1: "Sclerosi laterale amiotrofica (SLA)",
  sQ1O2: "Sclerosi multipla (SM)",
  sQ1O3: "Ictus",
  sQ1O4: "Lesione cerebrale traumatica (TBI)",
  sQ1O5: "Lesione del midollo spinale",
  sQ1O6: "Altri",

  sQ2: "2. Può usare l'occhio per comunicare?",
  sQ2O1: "No",
  sQ2O2: "Sì (Descrivere il livello di efficienza)",

  sSituation1: "a). Si prega di descrivere la situazione abitativa.",
  sSituation2:
    "b). Si prega di descrivere i diversi movimenti muscolari visibili.",
  sNotes: "",
  sSubmit: "Invia",
  /** End of Screening page */

  // volunteer
  //Section 1.
  //Header
  vtHeader: "Volontario con noi",
  vtPara1:
    "ALS Voice è un'organizzazione senza scopo di lucro che mira a fornire un servizio personalizzato di comunicazione alle persone con deficit di comunicazione. Abbiamo bisogno dell'assistenza costante di volontari che possano contribuire alla causa. Senza di voi non possiamo fare quello che facciamo.",
  vtPara2:
    "Tu sei il cuore di questa grande causa sociale. Puoi aiutarci in diversi modi, come la sensibilizzazione, l'aiuto con soluzioni di comunicazione, la raccolta di donazioni e molte altre strade stimolanti.",
  vtQuote:
    "Il miracolo non è che facciamo questo lavoro, ma che siamo felici di farlo",
  vtQuoteN: "Madre Teresa",
  vtFaqH: "FAQ sul volontariato",
  vtFaqP1:
    "Di seguito ci sono alcune domande e risposte che possono aiutarti nella decisione di fare volontariato con noi:",
  vtFaqQ1: "Vengo pagato per il mio lavoro di volontariato?",
  vtFaqA1:
    "Non in termini monetari. È un'opportunità di volontariato non retribuito.",
  vtFaqQ2:
    "Ci sono delle ore fisse assegnate per me da completare come volontario? ",
  vtFaqA2:
    "No. A seconda della disponibilità e del tempo libero, si può fare volontariato.",
  vtFaqQ3: "In che tipo di lavoro mi impegnerà l'organizzazione?",
  vtFaqA3:
    "Dalla raccolta di fondi alla sensibilizzazione, dall'ispirare le persone a iscriversi al nostro registro al sostenere i nostri straordinari donatori, c'è un ruolo adatto a tutti. E in cambio, incontrerai nuove persone, acquisirai nuove competenze, aumenterai il tuo CV e ti sentirai bene sapendo di aver contribuito a dare voce a un individuo con deficit di comunicazione.",
  vtFaqQ4: "Che impatto avrei io, come volontario, sull'organizzazione? ",
  vtFaqA4:
    "La soddisfazione derivata dal fornire mezzi di comunicazione e la soddisfazione ottenuta nel vedere le persone con deficit di comunicazione comunicare. Facendo il volontario per noi di ALS Voice, stai potenziando l'espressione di te stesso.",
  vtFaqQ5:
    "Quale sarebbe il mio incentivo per entrare a far parte della vostra organizzazione?",
  vtFaqA5:
    "Ti coinvolgeremo in un ruolo adatto a te, in base al modulo di volontariato che compilerai. In cambio avrai modo di affinare le tue capacità interpersonali, organizzative e le tue soft skills, che andranno a beneficio della tua carriera professionale e della tua vita personale. Riceverai anche un certificato di eccellenza per il tuo sforzo di volontariato.",
  /** End of volunteer page */

  // privacy policy
  //Section 1.
  //Header
  ppHeader: "Politica sulla privacy",
  ppIntro: "1. Introduzione",
  ppIntroDes:
    "ALS Voice considera la privacy dei suoi visitatori estremamente importante. Questo documento di politica della privacy descrive in dettaglio i tipi di dati personali che vengono raccolti e registrati da www.alsvoice.org e come li utilizziamo. ALS Voice si impegna per la privacy degli utenti e garantisce la sicurezza dei dati personali. I tuoi dati sono coscienziosamente protetti da perdita, distruzione, distorsione/falsificazione, manipolazione, accesso non autorizzato o divulgazione non autorizzata attraverso misure tecniche e organizzative appropriate. Il seguente documento rivela la nostra politica sulla privacy. Le informazioni incluse di seguito sono un riassunto dettagliato di quali informazioni vengono raccolte e dove, attraverso quali partner, e come tali informazioni vengono utilizzate.",
  ppTrafic: "2. Attività del traffico",
  ppTraficDes:
    "ALS Voice gGmbH mantiene e gestisce l'infrastruttura che serve www.alsvoice.org. Attualmente, nessuna attività di traffico del sito web è tracciata da noi.",
  ppCkes: "3. Cookie",
  ppCkeDes:
    "ALS Voice gGmbH non utilizza cookie o tracking pixel per tracciare l'attività degli utenti sul suo sito web www.alsvoice.org",
  ppDtCol: "4. Raccolta dei dati",
  ppDtColDes:
    "Raccoglieremo informazioni di identificazione personale da voi solo se ci fornirete volontariamente tali informazioni mentre ci contattate. Le informazioni di identificazione personale includono nome, indirizzo e-mail e numero di telefono. Può sempre astenersi dal fornire informazioni di identificazione personale, comprendendo che ciò le impedirà di mettersi in contatto con noi.",
  ppDtRt: "5. Conservazione dei dati",
  ppDtRtDes:
    "Ci sforziamo di limitare il più possibile le nostre attività di trattamento dei vostri dati personali. Quando si utilizzano i nostri servizi, i dati personali forniti dall'utente saranno conservati solo per il tempo necessario a soddisfare lo scopo per il quale sono stati raccolti o per il tempo richiesto dai requisiti di conservazione previsti dalla legge.",
  ppThrd: "6. Terze parti",
  ppThrdDes:
    "Non vendiamo, scambiamo o trasferiamo in altro modo a parti esterne le informazioni che raccogliamo su www.alsvoice.org. Non utilizziamo venditori terzi.",
  ppCnst: "7. Consenso",
  ppCnstDes:
    "Utilizzando la nostra applicazione, l'utente acconsente alla nostra politica sulla privacy e ne accetta i termini.",
  ppUsrRgt: "8. Diritti dell'utente",
  ppUsrRgtDes:
    "In base alla legislazione applicabile a voi, potreste avere il diritto di esercitare alcuni o tutti i seguenti diritti:",
  ppUsr1:
    "Diritto di accesso (art. 15 GDPR): Hai il diritto di ottenere informazioni sui dati che trattiamo che ti riguardano. Su richiesta, vi forniremo una copia dei dati e ulteriori informazioni nella misura definita nell'Art. 15 GDPR.",
  ppUsr2:
    "Diritto di rettifica (art. 16 GDPR): Avete il diritto di rettificare i vostri dati qualora questi siano errati o incompleti.",
  ppUsr3:
    "Diritto alla cancellazione (Art. 17 GDPR): Hai il diritto di cancellare i dati che non sono più necessari per le finalità originarie o che sono trattati illegalmente, come descritto nell'Art. 17 GDPR. Laddove alcuni dati siano soggetti a periodi di conservazione, limiteremo il trattamento alla durata e agli scopi previsti di tale periodo invece di cancellare i dati.",
  ppUsr4:
    "Diritto alla limitazione del trattamento (Art. 18 GDPR): Su vostra richiesta, limiteremo il trattamento dei dati personali secondo l'Art. 18, laddove vi siano incertezze sul nostro diritto di trattare tali dati o mentre è in corso una decisione relativa alla vostra opposizione a tale trattamento. In questi casi, conserveremo solo i dati, limiteremo qualsiasi trattamento nella misura minima necessaria e ritireremo l'accesso ai vostri dati dal nostro team di gestione del sito web.",
  ppUsr5:
    "Diritto di opporsi al trattamento basato sull'art. 6 sec. 1 lit. f GDPR: Su vostra obiezione cesseremo qualsiasi trattamento dei vostri dati personali basato sull'Art. 6 lit. f. Laddove abbiamo motivi legittimi impellenti per elaborare i vostri dati, siamo autorizzati a trattare ulteriormente tali dati, a condizione che il nostro interesse a farlo prevalga in una ponderazione rispetto al vostro interesse contro l'attività di trattamento. Pertanto, per consentirci di valutare la vostra richiesta, vi preghiamo di comunicarci il motivo della vostra opposizione.",
  ppUsr6:
    "Diritto di ritirare il consenso: Se il trattamento si basa sul tuo consenso, hai il diritto di ritirare il tuo consenso in qualsiasi momento inviando un'e-mail contact@alsvoice.org, senza pregiudicare la liceità del trattamento basato sul consenso prima della sua revoca.",
  ppUpdt: "9. Aggiornamento",
  ppUpdtDes:
    "Questa informativa sulla privacy è stata aggiornata l'ultima volta il: 15 febbraio 2022. Se dovessimo aggiornare, modificare o apportare modifiche alla nostra politica sulla privacy, tali modifiche saranno pubblicate qui.",
  ppInfo: "10. Più informazioni",
  ppInfoDes:
    "Se avete bisogno di ulteriori informazioni o avete domande sulla nostra politica sulla privacy o una richiesta, non esitate a contattarci via e-mail all'indirizzo contact@alsvoice.org",
  ppAls: "11. Solo l'informativa sulla privacy del sito web di ALS Voice",
  ppAlsDes:
    "Questa politica sulla privacy si applica solo al sito web www.alsvoice.org e alle informazioni condivise e/o raccolte in esso. Questa politica non si applica a qualsiasi informazione raccolta offline, o attraverso canali diversi da www.alsvoice.org.",
  /** End of privacy policy page */

  // Legal page
  //Section 1.
  //Header
  leHeader: "Condizioni legali",
  leImprt: "Impronta",
  leAd1: "ALS Voice gGmbH",
  leAd2: "Brunnenstrasse 23",
  leAd3: "72116, Moessingen, Germany",
  leMdHrd: "Amministratore delegato autorizzato a rappresentare",
  leMdNme: "Nome: Dr. Ujwal Chaudhary",
  leBdHdr: "",
  leBdN1: "",
  leBdN2: "",
  leBdN3: "",
  leCon: "Contact",
  lePhn: "",
  leEmail: "Email: contact@alsvoice.org",
  leRgtEnt: "Iscrizione al registro",
  leRgPnt1: "Registrazione nel registro commerciale.",
  leRgPnt2: "Tribunale registrato: Il tribunale di Stoccarda",
  leRgPnt3: "Numero di registro: HRB 782713",
  leRgPnt4: "Codice fiscale: 86168/05109",
  leRgPnt5: "ID IVA: DE349646979",
  // "",
  leRgPnt6:
    "La Commissione europea fornisce una piattaforma per la risoluzione delle controversie online (OS): http://ec.europa.eu/consumers/odr",
  leRgPnt7: "Potete trovare il nostro indirizzo e-mail sopra nell'Imprint.",
  leRgPnt8:
    "Non siamo disposti né obbligati a partecipare a un procedimento di risoluzione delle controversie davanti a una commissione di arbitrato dei consumatori.",
  leFtr:
    "Si prega di indirizzare le vostre domande generali a contact@alsvoice.org",
  /** End of Legal page page */

  /**Donation Page */
  dMkd: "Fare la differenza",
  dHlp: "Aiutaci a fornire",
  dAlsD: "Conto di donazione ALS Voice",
  dComingS: "Funzionalità di donazione online in arrivo",

  /**Contact Us */

  cuFname: "Nome",
  cuFnameP: "Inserisci il tuo nome ",
  cuLname: "Cognome",
  cuLnameP: "Inserisci il tuo cognome ",
  cuEmail: "Email *",
  cuEmailP: "Per favore, inserisci la tua email *",
  cuEmailE: "È richiesta un'email valida.",
  cuMsg: "Messaggio *",
  cuMsgP: "Scrivi qui il tuo messaggio.",
  cuMsgE: "Per favore, lasciateci un messaggio.",
  cuSend: "Inviare un messaggio",

  cuSuccess:
    "Grazie per aver contattato la nostra famiglia ALS Voice. La contatteremo presto.",
  cuError:
    "Qualcosa è andato storto! Si prega di ricaricare la pagina e riprovare.",

  /**Donation */
  doTitle: "La tua donazione",
  doSubTitle: "Inserisci l'importo che desideri:",
  doPlaceHolder: "Importo minimo 55",
  doYrDTitle: "I tuoi dati",
  doPersonalTle:
    "Per favore, compila i tuoi dati. I campi contrassegnati con * sono obbligatori. Non condivideremo mai i tuoi dati con altre persone.",
  doSalutation: "saluto *",
  doSelect: "Per favore seleziona",
  doFname: "Nome *",
  doLname: "Nome e cognome *",
  doEmail: "Indirizzo e-mail *",
  doCertTitle:
    "Per il certificato di donazione, si prega di fornire le seguenti informazioni.",
  doStreet: "Strada*",
  doNr: "Nr *",
  doZip: "Codice postale *",
  doCity: "Città *",
  doCountry: "Paese *",
  doPayOption: "La tua opzione di pagamento",
  doServiceTxt: "Si prega di scegliere",
  doRedirectS:
    "Sarai passato automaticamente al sistema Stripe Payment Gateway",
  doDirectPay: "Pagamento bancario diretto a ALS Voice",
  doAlsAcc: "Conto di donazione ALS Voice",
  doCC: "Carta di credito",
  doSubmit: "Invia",

  doValAmt: "Si prega di inserire l'importo minimo di 55",
  doValSal: "Si prega di selezionare il saluto ",
  doValName: "Inserisci un valore",
  doValEmail: "L'e-mail non è valida o manca",
  doValStreet: "Manca la strada",
  doValNr: "Il numero non è valido o manca",
  doValZip: "Il codice postale non è valido o manca",
  doValCity: "La città non è valida o manca",
  doValCC:
    "Si prega di scegliere almeno 1 opzione di pagamento servizio che si desidera pagare",
  doVerTxt: "Per favore, verifica i tuoi dati",
  doVerAmt: "L'importo della tua donazione selezionata:",
  doVerOpt: "Il metodo di pagamento selezionato",
  doResTxt:
    "Ente responsabile: ALS Voice gGmbH. Il trattamento è fatto per una donazione corretta, la contabilità finanziaria, l'esecuzione di compiti di interesse pubblico, e il legittimo interesse di ALS Voice gGmbH. Potete trovare ulteriori informazioni qui.",
  doStsTxt: "Il tuo stato di pagamento:",
  doTnx: "Grazie per il vostro sostegno!",
  doSuccessTxt: `La tua donazione ci aiuta a dare voce a persone
  che non ne hanno. Per la ricevuta della donazione, si prega di
  inviare i dettagli della dettagli della donazione a contact@alsvoice.org.
  Riceverai il tuo certificato di donazione a breve.`,
  doWarmReg: "Cordiali saluti e grazie dalla famiglia di ALS Voice",
  doErrTxt: "Qualcosa è andato storto",
  doErrSbTxt:
    "Si prega di riprovare. Il tuo pagamento non è andato a buon fine.",
  doErrCSTxt: "La tua donazione ci aiuta a dare voce a chi non ne ha.",
  doDonation: "Donazione",
  doBack: "Indietro",
  doHome: "Home",
  doSuccess: "Successo",
  doTransparency: "Trasparenza",
  doTranTxt: `The ALS Voice raccoglie fondi per fornire un mezzo personalizzato di
  avanzata soluzione di comunicazione basata sulle neurotecnologie ai pazienti di
  tutto il mondo.`,
  doHlp: "La tua donazione aiuta!",
  doDonr: "Servizio donatori ALS Voice",
  doFrm: "Modulo di donazione",
  doOvr: "Panoramica dei dati",
  doTransferred: "Donazione trasferita",
  doMis: "Signora",
  doMr: "Signor",
  doFm: "Famiglia",
  doMrMs: "Signor & Signora",

  /**Speak Up */

  spChl: "Cos'è la #speakchallenge",
  spStoryElH: "Importanza della comunicazione",
  spStoryEl:
    "La paralisi completa tiene gli individui chiusi nel loro corpo senza alcuna possibilità di espressione. Spinge l'individuo a uno stato di esclusione. La #speakchallenge ha lo scopo di sensibilizzare l'opinione pubblica sul problema della comunicazione affrontato dalle persone completamente paralizzate, e ALS Voice la organizza.",
  spHowH: "Come partecipare alla #speakchallenge?",
  spStoryVH: "La storia di Valentina",
  spStoryV1:
    "Mio fratello Fabio è morto 2 anni fa. Era affetto da SLA. Sto lavorando con ingegneri, scienziati e psicologi di tutto il mondo. Abbiamo fondato un'organizzazione no-profit e stiamo promuovendo questa #speakchallenge: \"cosa diresti se non potessi dire?\"... ce l'ha detto mio fratello con l'eye tracking: ",
  spStoryVQt: "“La vita è un dono... godetene!”.",
  spStoryV2: "Cosa direte? Seguiteci e aiutateci.",
  spChStpH: "La #SpeakChallenge è qui!",
  spSt1H: "PARTECIPARE",
  spSt1Txt:
    'Registrate un piccolo video o scrivete "cosa direste quando non potete dirlo".',
  spSt2H: "CONDIVIDI",
  spSt2Txt:
    "Condividete il video o il testo registrato con i vostri amici, familiari, parenti o chiunque conosciate su diversi social media e utilizzate l'hashtag #alsvoice #speakchallenge.",
  spSt3H: "INVITO",
  spSt3Txt:
    "Invitate amici, parenti e familiari a partecipare alla #speakchallenge.",
  spSt4H: "DONAZIONE",
  spSt4Txt:
    "ALS Voice è gestito da un piccolo gruppo di volontari. Se vi sentite spinti a sostenere il progetto e volete dimostrare il vostro apprezzamento, siete caldamente invitati a fare una donazione cliccando sul pulsante qui sotto.",
  spStoryElVideo: "8HR9dtz4ehI",
};
