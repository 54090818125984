import React, { useState, useEffect } from "react";
import useTranslation from "../../components/customHooks/translations";

import { Topbar } from "../../components/Navigations/Topbar";
import { Footer } from "../../components/Footer";

import YoutubeEmbed from "../../components/YoutubeEmbed";

import patientCollage from "../../assets/img/patientcollage.png";

const SpeakUp = () => {
  const t = useTranslation();
  return (
    <div>
      <Topbar />
      <div className="container-fluid mt-3">
        <div className="row">
          <div className="col">
            <div className="center marginTop50 ">
              <div className="card no-background">
                <div className="card-body">
                  <img
                    src={patientCollage}
                    alt="Lights"
                    style={{
                      width: "50%",
                      marginLeft: "auto",
                      marginRight: "auto",
                      display: "block",
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row">
          <div className="col">
            <div className="center marginTop25 ">
              <div className="card no-background">
                <div className="card-body">
                  <p className="card-text header">{t.spChl}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="p-5">
                <YoutubeEmbed embedId={t.spStoryElVideo} />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="p-5">
                <h2 className="display-4">{t.spStoryElH}</h2>
                <p className="display-p">{t.spStoryEl}</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="container-fluid">
        <div className="row">
          <div className="col">
            <div className="center marginTop25 ">
              <div className="card no-background">
                <div className="card-body">
                  <p className="card-text header">{t.spHowH}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-12 order-lg-2">
              <div className="p-5">
                <YoutubeEmbed embedId="KN3bu5slMD4" />
              </div>
            </div>
          </div>
          <div className="row align-items-center">
            <div className="col-lg-12 order-lg-1">
              <div className="p-5">
                <h2 className="display-4">{t.spStoryVH}</h2>
                <p className="display-p">
                  {t.spStoryV1}
                  <b>{t.spStoryVQt}</b>
                  {t.spStoryV2}
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="bandedBlock bb3" id="bb3">
        <h1 className="center-item">{t.spChStpH}</h1>
        <ol class="steps-container">
          <li>
            <span class="step">1</span>
            <div class="step-details">
              <span class="action">{t.spSt1H}</span>
              <span class="action-details">{t.spSt1Txt}</span>
            </div>
          </li>
          <li>
            <span class="step">2</span>
            <div class="step-details">
              <span class="action">{t.spSt2H}</span>
              <span class="action-details">{t.spSt2Txt}</span>
            </div>
          </li>
        </ol>

        <div className="container">
          <div className="row">
            <div className="col">
              <div className="social-media-link">
                <span>{t.ftFindUs.toUpperCase()}</span>
                <a
                  className="btn-social mx-1"
                  href="https://www.linkedin.com/company/als-voice-ggmbh/"
                  target="_blank"
                >
                  <i className="fab fa-linkedin"></i>
                </a>
                <a
                  className="btn-social mx-1"
                  href="https://www.youtube.com/channel/UCvvgq8-TsoLkSZKfQXEocdg"
                  target="_blank"
                >
                  <i className="fab fa-youtube"></i>
                </a>
                <a
                  className="btn-social mx-1"
                  href="https://www.instagram.com/alsvoice_/"
                  target="_blank"
                >
                  <i className="fab fa-instagram"></i>
                </a>
                <a
                  className="btn-social mx-1"
                  href="https://twitter.com/als_voice"
                  target="_blank"
                >
                  <i className="fab fa-twitter"></i>
                </a>
                <a
                  className="btn-social mx-1"
                  href="https://t.me/alsvoice"
                  target="_blank"
                >
                  <i className="fab fa-telegram"></i>
                </a>
              </div>
            </div>
          </div>
        </div>

        <ol class="steps-container mt-5">
          <li>
            <span class="step">3</span>
            <div class="step-details">
              <span class="action">{t.spSt3H}</span>
              <span class="action-details">{t.spSt3Txt}</span>
            </div>
          </li>
          <li>
            <span class="step">4</span>
            <div class="step-details">
              <span class="action">{t.spSt4H}</span>
              <span class="action-details">{t.spSt4Txt}</span>
            </div>
          </li>
        </ol>
      </div>
      <Footer />
    </div>
  );
};

export default SpeakUp;
