import React, { useState, useEffect } from 'react'
import { NavLink } from 'react-router-dom';

import { Topbar } from '../../components/Navigations/Topbar';
import { Footer } from '../../components/Footer';

import EnSignals from '../../assets/img/signals-en.png';
import DeSignals from '../../assets/img/signals-de.png';
import ItSignals from '../../assets/img/signals-it.png';
// const signals = { EnSignals, DeSignals, ItSignals };
import EnComm from '../../assets/img/comm-en.png';
import DeComm from '../../assets/img/comm-de.png';
import ItComm from '../../assets/img/comm-it.png';
// const comms = { EnComm, DeComm, ItComm };

import useTranslation from "../../components/customHooks/translations";

const Technology = ()=> {
    const [signal, setSignal] = useState(EnSignals);
    const [comm, setComm] = useState(EnComm);
    const t = useTranslation();
    useEffect(() => {
        if(t._language === 'de'){
            setSignal(DeSignals);
            setComm(DeComm);
        }else if(t._language === 'it'){
            setSignal(ItSignals);
            setComm(ItComm);
        }else{
            setSignal(EnSignals);
            setComm(EnComm);
        }
    }, [t])
    return (
        <div>
            <Topbar/>
            <div className="container-fluid">
                <div className="row">
                    <div className="col">
                        <div className="center marginTop50 pt-2">
                            <div className="card no-background">
                                <div className="card-body">
                                    <p className="card-text header">{t.tlHeader}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        <div className="container h-100">
            <div className="row h-100 py-2 pb-5">
                <div className="col-12">
                    <div className="card h-100 justify-content-center">
                        <div className="card-body">
                        <p className="card-text-a font-weight-bold" style={{color: '#057bf6'}}> {t.tlReadSig}</p>
                        <p className="card-text-a">{t.tlReadSigDes}</p>
                        </div>
                    </div>
                </div>
                <div className="col-12">
                    <div className="card h-100 justify-content-center">
                        <div className="card-body">
                            <img src={signal} alt="Lights" style={{width: '100%'}}/>
                            <p className="card-text-a">{t.tlibci}</p>
                        </div>
                    </div>
                </div> 
                <div className="col-12">
                    <div className="card h-100 justify-content-center">
                        <div className="card-body">
                        <p className="card-text-a font-weight-bold" style={{color: '#057bf6'}}> {t.tlComm}</p>
                        <ul>
                            <li className="card-text-a">{t.tlCommDes1}</li>
                            <li className="card-text-a">{t.tlCommDes2}</li>
                        </ul>
                        </div>
                    </div>
                </div>
                <div className="col-12">
                    <div className="card h-100 justify-content-center">
                        <div className="card-body">
                            <img src={comm} alt="Lights" style={{width: '100%'}}/>
                            <ul>
                                <li className="card-text-a">{t.tlCommDes3}</li>
                            </ul>
                            <p className="card-text-a pt-2">{t.tlCommDes4}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
            <Footer/>
        </div>
        )
}

export default Technology
