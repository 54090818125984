import React from 'react'
import { NavLink } from 'react-router-dom';

import { Topbar } from '../../components/Navigations/Topbar';
import { Footer } from '../../components/Footer';

import useTranslation from "../../components/customHooks/translations";

const Policy = ()=> {
    const t = useTranslation()
    return (
        <div>
            <Topbar/>
            <div className="container-fluid mt-5">
                <div className="row">
                    <div className="col">
                        <div className="center bottomMargin25 marginTop50 ">
                            <div className="card no-background">
                            <div className="card-body">
                                <p className="card-text header">{t.ppHeader}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="container h-100">
            <div className="row h-100 py-2">
                <div className="col-12">
                    <div className="card h-100 justify-content-center" style={{border: 'none'}}>
                        <div className="card-body">
                        <h3 className="card-title">{t.ppIntro}</h3>
                        <p className="card-text-a">{t.ppIntroDes} </p>
                        </div>
                    </div>
                </div>
                <div className="col-12">
                    <div className="card h-100 justify-content-center" style={{border: 'none'}}>
                        <div className="card-body">
                        <h3 className="card-title">{t.ppTrafic}</h3>
                        <p className="card-text-a">{t.ppTraficDes} </p>
                        </div>
                    </div>
                </div>
                <div className="col-12">
                    <div className="card h-100 justify-content-center" style={{border: 'none'}}>
                        <div className="card-body">
                        <h3 className="card-title">{t.ppCkes}</h3>
                        <p className="card-text-a">{t.ppCkeDes} </p>
                        </div>
                    </div>
                </div>
                <div className="col-12">
                    <div className="card h-100 justify-content-center" style={{border: 'none'}}>
                        <div className="card-body">
                        <h3 className="card-title">{t.ppDtCol}</h3>
                        <p className="card-text-a">{t.ppDtColDes} </p>
                        </div>
                    </div>
                </div>
                <div className="col-12">
                    <div className="card h-100 justify-content-center" style={{border: 'none'}}>
                        <div className="card-body">
                        <h3 className="card-title">{t.ppDtRt}</h3>
                        <p className="card-text-a">{t.ppDtRtDes} </p>
                        </div>
                    </div>
                </div>
                <div className="col-12">
                    <div className="card h-100 justify-content-center" style={{border: 'none'}}>
                        <div className="card-body">
                        <h3 className="card-title">{t.ppThrd}</h3>
                        <p className="card-text-a">{t.ppThrdDes} </p>
                        </div>
                    </div>
                </div>
                <div className="col-12">
                    <div className="card h-100 justify-content-center" style={{border: 'none'}}>
                        <div className="card-body">
                        <h3 className="card-title">{t.ppCnst}</h3>
                        <p className="card-text-a">{t.ppCnstDes} </p>
                        </div>
                    </div>
                </div>
                <div className="col-12">
                    <div className="card h-100 justify-content-center" style={{border: 'none'}}>
                        <div className="card-body">
                        <h3 className="card-title">{t.ppUsrRgt}</h3>
                        <p className="card-text-a font-weight-bold"> {t.ppUsrRgtDes}</p>
                        {/* <p className="card-text-a">{t.vtFaqP1}</p> */}
                        <p className="card-text-a">
                        <ol>
                            <li>{t.ppUsr1}</li>  
                            <li>{t.ppUsr2} </li>
                            <li>{t.ppUsr3}</li> 
                            <li>{t.ppUsr4}</li>
                            <li>{t.ppUsr5}</li>
                            <li>{t.ppUsr6}</li>
                        </ol>
                        </p>
                        <p className="card-text-a"></p>
                        </div>
                    </div>
                </div>

                <div className="col-12">
                    <div className="card h-100 justify-content-center" style={{border: 'none'}}>
                        <div className="card-body">
                        <h3 className="card-title">{t.ppUpdt}</h3>
                        <p className="card-text-a">{t.ppUpdtDes} </p>
                        </div>
                    </div>
                </div>
                <div className="col-12">
                    <div className="card h-100 justify-content-center" style={{border: 'none'}}>
                        <div className="card-body">
                        <h3 className="card-title">{t.ppInfo}</h3>
                        <p className="card-text-a">{t.ppInfoDes} </p>
                        </div>
                    </div>
                </div>
                <div className="col-12">
                    <div className="card h-100 justify-content-center" style={{border: 'none'}}>
                        <div className="card-body">
                        <h3 className="card-title">{t.ppAls}</h3>
                        <p className="card-text-a">{t.ppAlsDes} </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer/>
    </div>
        )
}

export default Policy;