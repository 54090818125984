import React from "react";

export const BasicHeader = ({ title, content, bgurl }) => {
  return (
    <header
      className="masthead zoom"
      // style={{backgroundColor: '#1C252D'}}
      // style={{backgroundImage: `url(${bgurl})`}}
    >
      <div className="container">
        <div className="row align-items-center justify-content-center text-center">
          <div className="col-lg-10">
            {/* <a className="btn mb-2 btn-header" href="#about">
                            {title}
                        </a> */}
            <h1
              className="text-uppercase font-weight-bold mb-0"
              style={{ color: "#6e2091" }}
            >
              {content}
            </h1>
          </div>
          {/* <div className="embed-responsive embed-responsive-16by9">
                        <iframe className="embed-responsive-item" src="https://www.youtube.com/embed/zpOULjyy-n8?rel=0" allowfullscreen></iframe>
                    </div> */}
        </div>
      </div>
    </header>
  );
};
BasicHeader.defaultProps = {
  title: "DEVELOPING BEDSIDE NEUROTECHNOLOGY",
  content: "HOW IT WORKS",
};
