import React from "react";

export const CarouselCard = ({ title, content, bgurl, btnTxt, btnUrl }) => {
  return (
    <header className="mhead" style={{ backgroundImage: `url(${bgurl})` }}>
      {/* // style={{height: '400px !important'}}> */}
      {/* // style={{height: '400px !important', backgroundImage: `url(${bgurl})`}}> */}
      <div className="container">
        <div className="row">
          <div className="col-lg-10">
            <h1 className="text-uppercase text-white font-weight-bold"></h1>
          </div>
          <div className="col-lg-5">
            <div className="card">
              <div className="card-body">
                <h5 className="card-title">{title}</h5>
                <p className="card-text">{content}</p>
              </div>
            </div>
          </div>
        </div>
        {btnTxt && (
          <div className="float-right">
            <a href={btnUrl} className="btn btn-outline-white">
              {btnTxt}
            </a>
          </div>
        )}
      </div>
    </header>
  );
};
