import React from "react";
import logo from "../../../assets/img/ALS_logo.svg";
import LanguageHandler from "../../LanguageHandler";
import useTranslation from "../../customHooks/translations";

export const Topbar = () => {
  const t = useTranslation();
  return (
    <nav className="navbar navbar-expand-lg navbar-dark bg-dark fixed-top">
      <a className="navbar-brand" href="/">
        <img src={logo} alt="logo" style={{ width: "100px" }} />
      </a>
      <button
        className="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon"></span>
      </button>

      <div
        className="collapse navbar-collapse pl-5"
        id="navbarSupportedContent"
      >
        <ul className="navbar-nav mr-auto">
          {/* <li className="nav-item px-3">
                            <a className="nav-link" href="#">
                                PATIENT STORIES
                            </a>
                        </li> */}

          <li className="nav-item px-3">
            <a className="nav-link" href="services">
              {t.navServ.toUpperCase()}
            </a>
          </li>
          <li className="nav-item px-3">
            <a className="nav-link" href="technology">
              {t.navTec.toUpperCase()}
            </a>
          </li>
          {/* <li className="nav-item px-3">
                            <a className="nav-link" href="vision">{t.navVisi.toUpperCase()}</a>
                        </li> */}
          <li className="nav-item px-3">
            <a className="nav-link" href="condition-and-communication">
              {t.navScr.toUpperCase()}
            </a>
          </li>
          <li className="nav-item px-3">
            <a className="nav-link" href="volunteer">
              {t.navVolu.toUpperCase()}
            </a>
          </li>
          <li className="nav-item px-3">
            <a className="nav-link" href="contact-us">
              {t.navContact.toUpperCase()}
            </a>
          </li>
          <li className="nav-item px-3">
            <a className="nav-link" href="media">
              {t.navMedia.toUpperCase()}
            </a>
          </li>
          <li className="nav-item px-3">
            <a className="nav-link" href="speak-up">
              {"speak-up".toUpperCase()}
            </a>
          </li>
          {/* <li className="nav-item px-3">
                            <a className="nav-link" href="#">
                                PATIENT SUMMARY
                            </a>
                        </li> */}
          {/* <li className="nav-item px-3">
                            <a className="nav-link" href="our-team">
                                TEAM
                            </a>
                        </li>
                        <li className="nav-item px-3">
                            <a className="nav-link" href="faq">
                                FAQ
                            </a>
                        </li> */}
        </ul>
        <LanguageHandler />
        &nbsp;
        <a href="donate" className="btn btn-blue" style={{ fontSize: "20px" }}>
          {t.navDon.toUpperCase()}
        </a>
      </div>
    </nav>
  );
};
