import React from 'react'
import { NavLink } from 'react-router-dom';

import { Topbar } from '../../components/Navigations/Topbar';
import { Footer } from '../../components/Footer';

import useTranslation from "../../components/customHooks/translations";

const Volunteer = ()=> {
    const t = useTranslation()
    return (
        <div>
            <Topbar/>
            <div className="container-fluid">
                <div className="row">
                    <div className="col">
                        <div className="center marginTop50 ">
                            <div className="card no-background">
                            <div className="card-body">
                                <p className="card-text header">{t.navVolu}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="container h-100">
            <div className="row h-100 ">
                <div className="col-12">
                    <div className="card h-100 justify-content-center">
                        <div className="card-body">
                        <h3 className="card-title">{t.vtHeader}</h3>
                        <p className="card-text-a font-weight-bold">{t.vtPara1} </p>
                        <p className="card-text-a pt-2">{t.vtPara2}</p>
                        <p className="card-text-a">
                            <figure class="quote">
                                <blockquote>
                                    <strong>{t.vtQuote}</strong>
                                </blockquote>
                                <figcaption>&mdash; {t.vtQuoteN}</figcaption>
                            </figure>
                        </p>
                        <p className="card-text-a font-weight-bold" style={{color: '#057bf6'}}> {t.vtFaqP1}</p>
                        <p className="card-text-a">
                        <ul className="question">
                            <li>{t.vtFaqQ1}</li>  
                            <li>{t.vtFaqA1}</li>  <br/>
                            <li>{t.vtFaqQ2} </li>
                            <li>{t.vtFaqA2} </li>  <br/>
                            <li>{t.vtFaqQ3}</li> 
                            <li>{t.vtFaqA3}</li>   <br/>
                            <li>{t.vtFaqQ4}</li>
                            <li>{t.vtFaqA4}</li>  <br/>
                            <li>{t.vtFaqQ5}</li>
                            <li>{t.vtFaqA5}</li>
                        </ul>
                        </p>
                        <p className="card-text-a"></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer/>
    </div>
        )
}

export default Volunteer