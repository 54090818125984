import en from "./en";
import de from "./de";
import it from "./it";
// import fr from "./fr";
// import hn from "./hn";

export default {
  en,
  de,
  it
  // fr,
  // hn
};
