import React, { useEffect } from 'react'
import { NavLink } from 'react-router-dom';

import { Topbar } from '../../components/Navigations/Topbar';
import { Footer } from '../../components/Footer';

// import tech1 from '../../assets/img/tech-1.svg';
import { ReactComponent as Logo } from '../../assets/img/tech-1.svg';
import tech2 from '../../assets/img/tech-2.png';

import useTranslation from "../../components/customHooks/translations";

const Works = () =>{
    const t = useTranslation();
    useEffect(() => {
    const brainSignals = [
        {id: 'Layer_2', txt: t.tibci},
        {id: 'Layer_3', txt: t.tnbci},
        {id: 'Layer_4', txt: t.teog},
        {id: 'Layer_5', txt: t.teeg},
        {id: 'Layer_6', txt: t.tnirs},
        {id: 'Layer_7', txt: t.tlfp},
        {id: 'Layer_8', txt: t.tmua},
        {id: 'Layer_9',  txt: t.tsua},
        {id: 'Layer_10', txt: t.tcog},
        {id: 'Layer_11', txt: t.tfmri},
        {id: 'Layer_13', txt: t.tH1},
        {id: 'Layer_12', txt: t.tH2}
    ]

        brainSignals.map(({id, txt})=>{
            document.getElementById(id).addEventListener('mouseover', function(e) {
                document.getElementById("img-txt").textContent = txt;
                e.target.style.fill = "blue";
                e.target.style.filter = "drop-shadow(2px 4px 6px blue)";
            });
            document.getElementById(id).addEventListener('mouseout', function(e) {
                document.getElementById("img-txt").textContent = 'Hover on the text to know more';
                e.target.style.fill = "black";
                e.target.style.filter = "none";
            });
        })
    }, [t])
    
    return (
        <div>
        <Topbar/>
        <div className="alternate" style={{paddingTop: '55px'}}>
            <section id="benefits" role="region">
            <div className="container-fluid">
                <div className="row">
                    <div className="col">
                        <div className="center bottomMargin25 marginTop50 ">
                            <div className="card no-border no-background">
                            <div className="card-body">
                            <p className="card-text header">{t.tHeader}</p>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
                </div>
            </section>
        </div>

        <section>
            <div className="container-fluid">
            <div className="row align-items-center">
            <div className="col-md-8">
                <div className="thumbnail">
                    {/* <a href={tech1}> */}
                        {/* <div className="p-5"> */}
                            {/* <img src={tech1} alt="Lights" style={{width: '110%'}}/> */}
                            <Logo style={{width: '110%'}}/>
                            <div className="caption">
                                {/* <p>Communication in amyotrophic lateral scierosis (ALS)</p> */}
                            </div>
                        {/* </div> */}
                    {/* </a> */}
                </div>
            </div>  
            <div className="col-md-4">
                {/* <p>Communication in amyotrophic lateral scierosis (ALS)</p>  */}
                <div className="container" style={{color: 'white', fontSize: 'larger', fontWeight: 'lighter'}}>
                    <div className="card" style={{width: '80%'}}>
                        <div className="card-body" style={{background: '#057bf6'}}>
                            <p className="card-text" id="img-txt">Hover on the text to know more</p>
                        </div>
                    </div>
                </div>

            </div>              
            </div>
            </div>
        </section>

        {/* <div className="container h-100">
            <div className="row h-100 py-2">
          
                <div className="col-12">
                    <div className="card h-100 justify-content-center no-border">
                        <div className="card-body">
                            
                            <p className="card-text-a">
                                <ul>
                                    <li>{t.tH1}</li>
                                    <li>{t.tH2}</li>
                                    <li>{t.tsua}</li>  
                                    <li>{t.tmua}</li>
                                    <li>{t.tlfp}</li> 
                                    <li>{t.tcog}</li>
                                    <li>{t.tnirs}</li>
                                    <li>{t.tfmri}</li>  
                                    <li>{t.teeg}</li>
                                    <li>{t.teog}</li> 
                                    <li>{t.tibci}</li>
                                    <li>{t.tnbci}</li>
                                </ul>
                            </p>
                            <p className="card-text-a">{t.tabb1}</p>
                        </div>
                    </div>
                </div>
               
            </div>
        </div> */}

        {/* <section>
            <div className="container">
            <div className="row align-items-center">
            <div className="col-md-8">
                <div className="thumbnail">
                    <a href={tech2}>
                        <div className="p-5">
                            <img src={tech2} alt="Lights" style={{width: '110%'}}/>
                            <div className="caption">
                            <p> 
                            </p>
                            </div>
                        </div>
                    </a>
                </div>
            </div>                
            </div>
            </div>
        </section> */}
        <div className="container h-100">
            <div className="row h-100 py-2">
                <div className="col-12">
                    <div className="card h-100 justify-content-center">
                        <div className="card-body">
                            <p className="card-text-a">{t.tabb2}</p>
                        </div>
                    </div>
                </div>           
            </div>
        </div>
       
        <Footer/>
    </div>
    )
}

export default Works;